
function getInitialState(){
  return{
    addCoachError: null,
    addCoachSuccess: null,
  }
 }

const coachReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case 'ADD_COACH_SUCCESS':
      return {
        ...state,
        addCoachError: null,
        addCoachSuccess: 'Coach Added Successfully'
      };
    case 'ADD_COACH_ERROR':
      return {
        ...state,
        addCoachError: action.err.message,
        addCoachSuccess: null
      };
    case 'EDIT_COACH_SUCCESS':
      return {
        ...state,
        addCoachError: null,
        addCoachSuccess: 'Coach Edited Successfuly'
      };
    case 'EDIT_COACH_ERROR':
      return {
        ...state,
        addCoachError: action.err.message,
        addCoachSuccess: null
      };
    case 'RESET':
      return getInitialState()
    default:
      return state;
  }
};

export default coachReducer; 