import React, { Component } from 'react'
import TeamSummary from './TeamSummary'

class TeamList extends Component {

    render() {

        const { teams, athletes, teamCat, auth } = this.props;

        return (
            <div className="row">
                {teams && teams.map((team, index) => {
                    return (
                        <TeamSummary team={team} athletes={athletes} teamCat={teamCat} index={index+1} auth={auth}/>
                    )
                })}
            </div>
        )
    }
}

export default TeamList
