import React, { Component } from 'react'
import EditRegistration from './EditRegistration';
import AddRegistration from './AddRegistration';
import { Redirect } from 'react-router-dom'

class Registrations extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { location } = this.props;
    if (!location.athlete) return <Redirect to='/registration' />
    return (
      <div className="dashboard container">
        {(location.athlete.categories) ?

          (location.athlete.categories.length > 0)
            ?
            <div className="row">
              <div className="col s12 m4">
                <EditRegistration athlete={location.athlete} individualCat={location.individualCat} />
              </div>
            </div>
            :
            <div className="row">
              <div className="col s12 m6">
                <AddRegistration athlete={location.athlete}  individualCat={location.individualCat} />
              </div>
            </div>
          : 
          <div className="row">
            <div className="col s12 m6">
              <AddRegistration athlete={location.athlete}  individualCat={location.individualCat} />
            </div>
          </div>
        }
      </div>
    )
  }
}


export default Registrations;