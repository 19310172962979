const initState = {
  addPairError: null,
  addPairSuccess: null,
}

const pairReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ADD_PAIR_SUCCESS':
      return {
        ...state,
        addPairError: null,
        addPairSuccess: 'Pair Added Successfully'
      };
    case 'ADD_PAIR_ERROR':
      return {
        ...state,
        addPairError: action.err.message,
        addPairSuccess: null
      };
    case 'EDIT_PAIR_SUCCESS':
      return {
        ...state,
        addPairError: null,
        addPairSuccess: 'Pair Edited Successfuly'
      };
    case 'EDIT_PAIR_ERROR':
      return {
        ...state,
        addPairError: action.err.message,
        addPairSuccess: null
      };
    case 'RESET':
      return {
        ...state,
        addPairError: null,
        addPairSuccess: null
      };
    case 'UPLOAD':
      var per = parseFloat(action.progress).toFixed(2);
      return {
        ...state,
        addPairError: null,
        addPairSuccess: 'Uploading video/s: ' + per + '%'
      };

    default:
      return state;
  }
};

export default pairReducer; 