import React, {Component} from 'react';
import { BrowserRouter } from 'react-router-dom'
import Navigation from './components/layout/Navigation'
import Footer from './components/layout/Footer'
import Dashboard from './components/dashboard/Dashboard'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App flex-wrapper ">
          <Navigation />
          <Dashboard />
          <br />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;


