const initState = {
  addAthleteError: null,
  addAthleteSuccess: null,
}

const athleteReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPLOAD':
      var per = parseFloat(action.progress).toFixed(2);
      return {
        ...state,
        addRegistrationError: null,
        addRegistrationSuccess: per < 100 ? 'Uploading video/s: '+per+'%' : 'Athlete Registered Successfully'
      };
    case 'ADD_ATHLETE_SUCCESS':
      return {
        ...state,
        addAthleteError: null,
        addAthleteSuccess: 'Athlete Added Successfully'
      };
    case 'ADD_ATHLETE_ERROR':
      return {
        ...state,
        addAthleteError: action.err.message,
        addAthleteSuccess: null
      };
    case 'EDIT_ATHLETE_SUCCESS':
      return {
        ...state,
        addAthleteError: null,
        addAthleteSuccess: 'Athlete Edited Successfuly'
      };
    case 'EDIT_ATHLETE_ERROR':
      return {
        ...state,
        addAthleteError: action.err.message,
        addAthleteSuccess: null
      };
    case 'RESET':
      return {
        ...state,
        addAthleteError: null,
        addAthleteSuccess: null
      };
    default:
      return state;
  }
};

export default athleteReducer; 