import React, { Component } from 'react'
import { deleteAthlete } from '../../store/actions/athleteActions'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { getAge } from '../../store/actions/otherActions'


class AthleteSummary extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    handleDelete = (athlete, e) => {
        this.props.deleteAthlete(athlete)
    }
    handleEdit = (athlete, e) => {
        this.props.history.push({ //browserHistory.push should also work here
            pathname: '/editAthlete/' + athlete.id,
            athlete: athlete,
        });
    }

    render() {

        const { athlete } = this.props;

        return (
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="card card-inverse card-info">
                    <div class="row">

                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <img class="card-img-top" alt="" src={athlete.image} />
                        </div>
                        <div class="col-sm-9 col-md-9 col-lg-9">

                            <div class="card-block">
                                <h4 class="card-title">{athlete.name.toUpperCase()} {athlete.surname.toUpperCase()}{athlete.para === "true" ? " (Para)" : ""}</h4>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card-text">
                                            DOB: {athlete.dob.toUpperCase()} (Age: {getAge(athlete.dob)})
                                        </div>
                                        <div class="card-text">
                                            Sex: {athlete.sex}
                                        </div>
                                        <div class="card-text">
                                            Grade: {athlete.grade.toUpperCase()}
                                        </div>
                                        <div class="card-text">
                                            Video 1: <a href={athlete.video1}>View</a>
                                        </div>
                                        <div class="card-text">
                                            Video 2: <a href={athlete.video2}>View</a>
                                        </div>
                                        {athlete.comments ?
                                            <div class="card-text">
                                                Comments: {athlete.comments}
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                {/*<button class="btn btn-secondary btn-sm" onClick={this.handleEdit.bind(this, athlete)}>Edit</button>
                                &nbsp;*/}
                                <button class="btn btn-secondary btn-sm" onClick={this.handleDelete.bind(this, athlete)}>Delete</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteAthlete: (athlete) => dispatch(deleteAthlete(athlete))
    }
}

export default compose(
    withRouter,
    connect(null, mapDispatchToProps)
)(AthleteSummary);