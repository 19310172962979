import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import PairList from './PairList'
import AddPair from './AddPair'
import EditPair from './EditPair'
import categories from "../../categories.json";
import { isAffiliated } from '../../store/actions/otherActions'
import { withTranslation } from 'react-i18next';

class Pairs extends Component {

  render() {
    const { location, pairs, athletes, auth, t } = this.props;
    var price = pairs.length * t('general_pairPrice') * (isAffiliated(auth) ? 8 / 10 : 1);
    var pairCat = categories.filter(cat => cat.category === "Pair");

    return (
      <div className="dashboard container">
        <div className="row">
          <div className="col-md-6">
            <h2>Pair Categories</h2>
            <br />
          </div>
          <div className="col-md-6 d-flex flex-row-reverse">
            {(price) ? (price > 0) ? <h2>Total: €{price}.00</h2> : <h2>Total: €--</h2> : <h2>Total: €--</h2>}
          </div>
        </div>

        {athletes.length > 1
          ?
          <div className="row">
            <div className="col s12 m4">
              {location.pair
                ? <EditPair pair={location.pair} athletes={athletes} pairCat={pairCat} />
                : <AddPair athletes={athletes} pairCat={pairCat} />
              }
              <br />
              <div class="alert alert-info" role="alert">Note: Uploaded videos must not exceed 300MB.</div>
            </div>
          </div>
          :
          <div class="alert alert-info" role="alert">Pair Registrations cannot be completed. Minimum of 2 athletes needed. Click
            <Link to='/athletes'> here </Link>
            to Register Athletes</div>
        }

        <div className="row">
          <div className="col s12 m6">
            <PairList pairs={pairs} athletes={athletes} pairCat={pairCat} auth={auth} />
          </div>
        </div>

      </div>

    )
  }
}


const mapStateToProps = (state) => {
  return {
    pairs: state.firestore.ordered[`pairs`] || [],
    athletes: state.firestore.ordered[`athletes`] || [],
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  firestoreConnect(props => {
    return [
      {
        collection: "users",
        doc: props.auth.uid,
        subcollections: [{ collection: "pairs", orderBy: ['createdAt'] }]
        , storeAs: `pairs`
      },
      {
        collection: "users",
        doc: props.auth.uid,
        subcollections: [{ collection: "athletes", orderBy: ['createdAt'] }]
        , storeAs: `athletes`
      }
    ];
  })
)(Pairs)