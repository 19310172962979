const initState = {
  addRegistrationError: null,
  addRegistrationSuccess: null,
}

const registrationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPLOAD':
      var per = parseFloat(action.progress).toFixed(2);
      return {
        ...state,
        addRegistrationError: null,
        addRegistrationSuccess: 'Uploading video/s: '+per+'%'
      };
    case 'ADD_PAIR_ERROR':
      return {
        ...state,
        addRegistrationError: action.err.message,
        addRegistrationSuccess: null
      };
    case 'EDIT_PAIR_SUCCESS':
      return {
        ...state,
        addRegistrationError: null,
        addRegistrationSuccess: 'Registration Edited Successfuly'
      };
    case 'EDIT_PAIR_ERROR':
      return {
        ...state,
        addRegistrationError: action.err.message,
        addRegistrationSuccess: null
      };
    case 'RESET_REG':
      return {
        ...state,
        addRegistrationError: null,
        addRegistrationSuccess: null
      };
    default:
      return state;
  }
};

export default registrationReducer; 