import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteRegistration } from '../../store/actions/registrationActions'
import { compose } from 'redux'
import { withRouter } from 'react-router'

class CatSummary extends Component {

    handleRemoveRegister = (e) => {
        const { athlete, cat } = this.props;
        this.props.deleteRegistration(athlete.id, cat.num)
        //this.props.history.push('/redirect');
    }

    render() {
        var kumite = ["Red Belt Video", "White/Blue Belt Video"]

        const { cat, category } = this.props;
        var array = [];
        for (var i = 0; i < cat.rounds; i++) {
            array[i] = "";
        }
        return (
            <div class="card-text">
                <div class="row">
                    <div class="col-sm-5">({cat.num}) {cat.name.toUpperCase()}</div>
                    <div class="col-sm-4">
                        {category && Object.keys(category).map(round => {
                            return (
                                cat.type == "Kata" 
                                ?
                                <div class="row">
                                    &nbsp;<a href={category[round].video}>Round {round}: {category[round].kata ? category[round].kata : "Video"}</a>
                                </div>
                                :
                                <div class="row">
                                   <a href={category[round].video}>{kumite[round-1]}</a>
                                </div>
                            )
                        })}
                    </div>
                    <div class="col-sm-2"><button type="button" class="btn btn-secondary" onClick={this.handleRemoveRegister}>Delete Category</button></div>
                </div>
            </div>


        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteRegistration: (id, category) => dispatch(deleteRegistration(id, category))
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    }

}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CatSummary);