import React, { Component } from 'react'
import { deletePair } from '../../store/actions/pairActions'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { isAffiliated } from '../../store/actions/otherActions'
import { withTranslation } from 'react-i18next';

class PairSummary extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    handleDelete = (pair, e) => {
        this.props.deletePair(pair)
    }
    handleEdit = (pair, e) => {
        this.props.history.push({ //browserHistory.push should also work here
            pathname: '/editPair/' + pair.id,
            pair: pair,
        });
    }

    render() {

        const { pair, athletes, pairCat, index, t } = this.props;
        var cat = pairCat && pairCat.find(cat => pair.category === cat.num);
        var array = [];
        for (var i = 0; i < cat.rounds; i++) {
            array[i] = i + 1;
        }

        return (
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="card card-inverse card-info">
                    <div class="row">
                        <div class="col-sm-9 col-md-9 col-lg-9">
                            <div class="card-block">
                                <h4 class="card-title">Pair {index}: ({cat.num}) {cat.name}</h4>
                                <div class="card-text">
                                    {athletes && athletes.map(athlete => {
                                        return (
                                            <div>
                                                {(athlete.id === pair.athlete1) ?
                                                    '- ' + athlete.name + ' ' + athlete.surname : ''}
                                                {(athlete.id === pair.athlete2) ?
                                                    '- ' + athlete.name + ' ' + athlete.surname : ''}
                                            </div>
                                        )
                                    })}
                                    {array.map(index => {
                                        return (
                                            <div>
                                                Round {index}:&nbsp;{pair[index] ? <a href={pair[index].video}>{pair[index].kata}</a> : ""}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="card-block">
                                <center>
                                    {/*<button class="btn btn-secondary btn-sm" onClick={this.handleEdit.bind(this, pair)}>Edit</button>
                                    &nbsp;&nbsp;*/}
                                    <button class="btn btn-secondary btn-sm" onClick={this.handleDelete.bind(this, pair)}>Delete</button>
                                    <div class="card-text">
                                        <br />
                                        <h3>€{t('general_pairPrice') * (isAffiliated(this.props.auth) ? 8/10 : 1)}.00</h3>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deletePair: (pair) => dispatch(deletePair(pair))
    }
}

export default compose(
    withRouter,
    connect(null, mapDispatchToProps),
    withTranslation()
)(PairSummary);