import authReducer from './authReducer'
import officialReducer from './officialReducer'
import coachReducer from './coachReducer'
import athleteReducer from './athleteReducer'
import clubReducer from './clubReducer'
import teamReducer from './teamReducer'
import pairReducer from './pairReducer'
import registrationReducer from './registrationReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
  auth: authReducer,
  club: clubReducer,
  official: officialReducer,
  athlete: athleteReducer,
  coach: coachReducer,
  team: teamReducer,
  pair: pairReducer,
  registration: registrationReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer

// the key name will be the data property on the state object 