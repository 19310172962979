import React, { Component } from 'react'
import IndividualSummary from './IndividualSummary'

class IndividualList extends Component {

    render() {

        const { athletes, individualCat } = this.props;
        
        return (
            <div className="row">
                {athletes && athletes.map(athlete => {
                    return (
                        <IndividualSummary key={athlete.id} athlete={athlete} individualCat={individualCat} />
                    )
                })}
            </div>
        )
    }
}

export default IndividualList;
