import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { withTranslation } from 'react-i18next';

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { auth, t } = this.props;
    if (!auth.uid) return <Redirect to='/signin' />

    return (
      <div>
        <h1><b>{t('name')}</b></h1>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <img src={process.env.PUBLIC_URL + '/cover.png'} alt="Cover"></img>
          </div>
        </div>
        <br /><br />

        <div class="row">
          <div class="col-md-12">
            <h1><b>Information</b></h1>

            <hr />

            <p>This  Para Online Registration System is open from 1st July 2022 and will be closed on 17th July 2022.</p>
            <p>The "Celebrating Para Martial Day Festival" will be Broadcasted on Saturday, 6th August (Malta time).  </p>
            <p>IMPORTANT : Please enter all the details required.</p>
            <p>TO PREPARE for UPLOAD :</p>
            <ul>
              <li>PASSPORT SIZE PHOTO  - FACE PHOTO</li>
              <li>VIDEO 1: Participant performs kata or combinations in the Martial Art he/ she practises.</li>
              <li>VIDEO 2: Participant shares his/ her life challenges in performing certain daily chores (may be also during training).</li>
            </ul>

            <p>Enjoy D Excellence </p>

            <p>Terms &amp; Conditions  :  <Link to='/disclamer'>Click here</Link></p>

            <div class="social-container">
              <h3>Follow Us:</h3>
              <a href={t('link_social_youtube')} className="youtube social" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>&nbsp;&nbsp;&nbsp;
              <a href={t('link_social_facebook')} className="facebook social" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>&nbsp;&nbsp;&nbsp;&nbsp;
              <a href={t('link_social_twitter')} className="twitter social" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>&nbsp;&nbsp;&nbsp;&nbsp;
              <a href={t('link_social_instagram')} className="instagram social" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.firestore.ordered.projects,
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'projects' }
  ]),
  withTranslation()
)(Home)