export const addPair = (registration, history, compID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid;

        const doc = firestore.collection('users').doc(authorId).collection('pairs').doc();
        const firebase = getFirebase();
        const storageRef = firebase.storage().ref();

        var metadata = {
            contentType: 'video/mp4'
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        addVideo(dispatch, firebase, storageRef, authorId, doc, metadata, registration, history, 1);
    }
};

export const addVideo = (dispatch, firebase, storageRef, authorId, doc, metadata, registration, history, i) => {
        // make async call to database

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.child(authorId + "/pairs/" + doc.id + '/' + i).put(registration.video[i-1], metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / registration.category.rounds) + ((i - 1) * (100/ registration.category.rounds));
                dispatch({ type: 'UPLOAD', progress });
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            }, function (error) {
                console.log("error");
            }, function () {
                // Upload completed successfully, now we can get the download URL
                //registration.proof && uploadProof(authorId + "/" + id + '/' + registration.category + "Proof", registration, getFirebase(), doc);
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    doc.set({
                        athlete1: registration.athlete1.id,
                        athlete2: registration.athlete2.id,
                        category: registration.category.num,
                        [i]: {
                            video: downloadURL,
                            style: registration.style ? registration.style : "",
                            kata: registration.kata[i - 1]
                        },
                        createdAt: new Date(),
                    }, { merge: true }).then(() => {
                        if (i != registration.category.rounds) {
                            addVideo(dispatch, firebase, storageRef, authorId, doc, metadata, registration, history, i + 1);
                        }
                        else {
                            dispatch({ type: 'ADD_PAIR_SUCCESS', registration });
                        }
                    }).catch(err => {
                        dispatch({ type: 'ADD_PAIR_ERROR', err });
                    });;

                });

            });

};

export const deletePair = (pair, compID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid;

        const doc = firestore.collection('users').doc(authorId).collection('pairs').doc(pair.id);

        doc.delete().then(function () {
            getFirebase().storage().ref().child(authorId + "/pairs/" + pair.id).delete();
            console.log("Document successfully deleted!");
        }).catch(function (error) {
            console.error("Error removing document: ", error);
        });
    }
}; 

export const editPair = (pair, id, compID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid;

        const doc = firestore.collection('users').doc(authorId).collection('pairs').doc(id);

        doc.update({
            ...pair,
        }).then(() => {
            dispatch({ type: 'EDIT_PAIR_SUCCESS', pair });
        }).catch(err => {
            dispatch({ type: 'EDIT_PAIR_ERROR', err });
        });
    }
};


export const resetErrors = () => {
    return (dispatch) => {
        dispatch({ type: 'RESET' });
    }
};