const initState = {
  addOfficialError: null,
  addOfficialSuccess: null,
}

const officialReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ADD_OFFICIAL_SUCCESS':
      return {
        ...state,
        addOfficialError: null,
        addOfficialSuccess: 'Official Added Successfully'
      };
    case 'ADD_OFFICIAL_ERROR':
      return {
        ...state,
        addOfficialError: action.err.message,
        addOfficialSuccess: null
      };
    case 'EDIT_OFFICIAL_SUCCESS':
      return {
        ...state,
        addOfficialError: null,
        addOfficialSuccess: 'Official Edited Successfuly'
      };
    case 'EDIT_OFFICIAL_ERROR':
      return {
        ...state,
        addOfficialError: action.err.message,
        addOfficialSuccess: null
      };
    case 'RESET':
      return {
        ...state,
        addOfficialError: null,
        addOfficialSuccess: null
      };
    default:
      return state;
  }
};

export default officialReducer; 