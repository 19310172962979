import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn, resetErrors } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'


class SignIn extends Component {
  state = {
    email: '',
    password: ''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleRegisterClick = (e) => {
    this.props.history.push('/signup')
  }
  handleForgetPassClick = (e) => {
    this.props.history.push('/resetPassword')
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }
  componentWillMount() {
    this.props.resetErrors();
  }
  render() {
    const { signinSuccess, signinError, auth } = this.props;
    if (auth.uid) return <Redirect to='/' />
    return (
      <main class="cotainer login-form">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="card">
              <div class="card-header"><h3>Log In</h3></div>
              <div class="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div class="form-group row">
                    <label htmlFor="email_address" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>
                    <div class="col-md-6">
                      <input type="email" id='email' class="form-control" required autofocus onChange={this.handleChange} />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label htmlFor="password" class="col-md-4 col-form-label text-md-right">Password</label>
                    <div class="col-md-6">
                      <input type="password" id='password' class="form-control" onChange={this.handleChange} required />
                    </div>
                  </div>

                  {/*
                  <div class="form-group row">
                    <div class="col-md-6 offset-md-4">
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="remember"> Remember Me</input>
                        </label>
                      </div>
                    </div>
                  </div>
    */}

                  <div class="col-md-6 offset-md-4">
                    <button type="submit" class="btn btn-primary">Log In</button>
                    <button class="btn btn-link" onClick={this.handleForgetPassClick} >Forgot Your Password?</button>
                  </div>

                  {signinError ? <div class="alert alert-danger" role="alert">{signinError}</div> : null}
                  {signinSuccess ? <div class="alert alert-success" role="alert">{signinSuccess}</div> : null}


                </form>
                <hr />
                <div class="col-md-12">
                  <center>
                    <label htmlFor="password" >Don't have an account.&nbsp;&nbsp;</label>
                    <button class="btn btn-secondary" button onClick={this.handleRegisterClick} >Create an Account</button>
                  </center>
                </div>
              </div>

            </div>

          </div>
        </div>
      </main>



    )
  }
}

const mapStateToProps = (state) => {
  return {
    signinError: state.auth.signinError,
    signinSuccess: state.auth.signinSuccess,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    resetErrors: () => dispatch(resetErrors())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)