import React, { Component } from 'react'
import { deleteTeam } from '../../store/actions/teamActions'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { isAffiliated } from '../../store/actions/otherActions'
import { withTranslation } from 'react-i18next';

class TeamSummary extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    handleDelete = (team, e) => {
        this.props.deleteTeam(team)
    }
    handleEdit = (team, e) => {
        this.props.history.push({ //browserHistory.push should also work here
            pathname: '/editTeam/' + team.id,
            team: team,
        });
    }

    render() {

        const { team, athletes, teamCat, index, t } = this.props;
        var cat = teamCat && teamCat.find(cat => team.category === cat.num);
        var array = [];
        for (var i = 0; i < cat.rounds; i++) {
            array[i] = i + 1;
        }

        return (
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="card card-inverse card-info">
                    <div class="row">
                        <div class="col-sm-9 col-md-9 col-lg-9">
                            <div class="card-block">
                                {teamCat && teamCat.map(cat => {
                                    return (
                                        // eslint-disable-next-line
                                        (team.category == cat.num) ?
                                    <h4 class="card-title">Team {index}: ({cat.num}) {cat.name}</h4> : ''
                                    )
                                })}
                                <div class="card-text">
                                    {athletes && athletes.map(athlete => {
                                        return (
                                            <div>
                                                {(athlete.id === team.athlete1) ?
                                                    '- ' + athlete.name + ' ' + athlete.surname : ''}
                                                {(athlete.id === team.athlete2) ?
                                                    '- ' + athlete.name + ' ' + athlete.surname : ''}
                                                {(athlete.id === team.athlete3) ?
                                                    '- ' + athlete.name + ' ' + athlete.surname : ''}
                                            </div>
                                        )
                                    })}
                                    {array.map(index => {
                                        return (
                                            <div>
                                                Round {index}:&nbsp;{team[index] ? <a href={team[index].video}>{team[index].kata}</a> : ""}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="card-block">
                                <center>
                                    {/*<button class="btn btn-secondary btn-sm" onClick={this.handleEdit.bind(this, team)}>Edit</button>
                                    &nbsp;&nbsp;*/}
                                <button class="btn btn-secondary btn-sm" onClick={this.handleDelete.bind(this, team)}>Delete</button>
                                
                                <div class="card-text">
                                <br/>
                                    <h3>€{t('general_teamPrice') * (isAffiliated(this.props.auth) ? 8/10 : 1)}.00</h3>
                                </div>
                                </center>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteTeam: (team) => dispatch(deleteTeam(team))
    }
}

export default compose(
    withRouter,
    connect(null, mapDispatchToProps),
    withTranslation()
)(TeamSummary);