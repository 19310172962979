import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { editPair } from '../../store/actions/pairActions'
import { validateForm } from '../../store/actions/otherActions'
import '../../css/funkyradio.css'

class EditPair extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    state = {
        errors: {
            athlete1: '',
            athlete2: '',
            category: '',
        }
    }
    handleRadioChange = (e) => {
        const { name, value } = e.target;
        let errors = this.state.errors;
        errors.category = '';
        this.setState({ errors, [name]: value })
    }
    handleChange = (e) => {
        const { id, value } = e.target;

        let errors = this.state.errors;

        switch (id) {
            case 'athlete1':
                errors.athlete1 =
                    value.length === 0
                        ? 'Please choose an athlete!'
                        : '';
                break;
            case 'athlete2':
                errors.athlete2 =
                    value.length === 0
                        ? 'Please choose an athlete!'
                        : '';
                break;
            default:
                break;
        }

        this.setState({ errors, [id]: value })

    }
    handleClose = (e) => {
        this.props.history.push('/pairs')
    }
    handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm(this.state.errors)) {
            this.props.editPair(this.state, this.props.location.pair.id);
            this.props.history.push('/pairs');
        } else {
            console.error('Invalid Form')
        }
    }

    componentWillReceiveProps() {
        document.getElementById("form").reset();
        this.setState({ category: null , athlete1: null, athlete2: null })
        window.scrollTo(0, 0);
    }

    render() {

        const { athletes, pairCat, pair } = this.props;

        if (!this.state.category || this.state.category===null)this.setState({ category: pair.category , athlete1: pair.athlete1, athlete2: pair.athlete2 })

        return (
            <div class="col-12-md">

                <div class="card card-inverse card-info">

                <form id="form" onSubmit={this.handleSubmit}>
                        <br /><h3 class="">Edit Pair</h3>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label htmlFor="athlete1">Athlete 1*</label>
                                <select class="form-control" id="athlete1" onChange={this.handleChange}>
                                    <option value=''>-Select Athlete-</option>
                                    {athletes && athletes.map(athlete => {
                                        return (
                                            (this.state.athlete2 === athlete.id) ? '' :
                                                <option selected={this.state.athlete1 === athlete.id} value={athlete.id}>{athlete.name} {athlete.surname}</option>
                                        )
                                    })}
                                </select>
                                {this.state.errors.athlete1.length > 0 && <span className='error'>{this.state.errors.athlete1}</span>}
                            </div>
                            <div class="form-group col-md-6">
                                <label htmlFor="athlete2">Athlete 2*</label>
                                <select class="form-control" id="athlete2" onChange={this.handleChange}>
                                    <option value=''>-Select Athlete-</option>
                                    {athletes && athletes.map(athlete => {
                                        return (
                                            (this.state.athlete1 === athlete.id) ? '' :
                                                <option selected={this.state.athlete2 === athlete.id} value={athlete.id}>{athlete.name} {athlete.surname}</option>
                                        )
                                    })}
                                </select>
                                {this.state.errors.athlete2.length > 0 && <span className='error'>{this.state.errors.athlete2}</span>}
                            </div>
                        </div>
                        <div class="form-group">
                            <label htmlFor="category">Category*</label>
                            <div id="category" class="funkyradio" onChange={this.handleRadioChange}>
                                <label>&nbsp;Kata</label>
                                {pairCat && pairCat.map(cat => {
                                    return (
                                        (cat.type === "KATA") ?
                                            <div class="funkyradio-success">
                                                <input checked={pair && pair.category.toString() === cat.num.toString()} class="form-control" type="radio" name="category" value={cat.num} id={cat.id}  />
                                                <label for={cat.id}>{cat.num} - {cat.name}</label>
                                            </div> : ''
                                    )
                                })}
                                <label>&nbsp;Kobudo</label>
                                {pairCat && pairCat.map(cat => {
                                    return (
                                        (cat.type === "KOBUDO") ?
                                            <div class="funkyradio-success">
                                                <input defaultChecked={pair && pair.category.toString() === cat.num.toString()} class="form-control" type="radio" name="category" value={cat.num} id={cat.id} />
                                                <label for={cat.id}>{cat.num} - {cat.name}</label>
                                            </div> : ''
                                    )
                                })}
                            </div>
                            {this.state.errors.category.length > 0 && <span className='error'>{this.state.errors.category}</span>}
                        </div>
                        <button type="submit" class="btn btn-primary">Save Changes</button>&nbsp;&nbsp;
                        <button type="button" class="btn btn-secondary" onClick={this.handleClose}>Close</button>
                    </form>
                </div>

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        pairError: state.pair.pairError,
        pairSuccess: state.pair.pairSuccess,
        auth: state.firebase.auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editPair: (club, id) => dispatch(editPair(club, id))
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EditPair);