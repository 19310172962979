import React, { Component } from 'react'
import PairSummary from './PairSummary'

class PairList extends Component {

    render() {

        const { pairs, athletes, pairCat, auth } = this.props;

        return (
            <div className="row">
                {pairs && pairs.map((pair, index) => {
                    return (
                        <PairSummary pair={pair} athletes={athletes} pairCat={pairCat} index={index+1} auth={auth}/>
                    )
                })}
            </div>
        )
    }
}

export default PairList
