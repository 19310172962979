import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { isAffiliated } from '../../store/actions/otherActions'
import { withTranslation } from 'react-i18next';

class Report extends Component {
  state = {
  }
  handleClick = (e) => {
    e.preventDefault();
    window.print();
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { athletes, pairs, teams, merch, t } = this.props;
    const user = this.props.user && this.props.user[0];

    var individualPrice = t('general_individualPrice') * (isAffiliated(this.props.auth) ? 8 / 10 : 1);
    var pairPrice = t('general_pairPrice') * (isAffiliated(this.props.auth) ? 8 / 10 : 1);
    var teamPrice = t('general_teamPrice') * (isAffiliated(this.props.auth) ? 8 / 10 : 1);
    var totalIndividualPrice = athletes.reduce((a, b) => a + ((b['categories'] ? Object.keys(b['categories']).length : 0) || 0), 0) * individualPrice;
    var totalPairPrice = pairs.length * pairPrice;
    var totalTeamPrice = teams.length * teamPrice;
    var totalMerchPrice = merch.reduce((a, b) => a + (b.price * (user && user[b.id] ? user[b.id] : 0)), 0);

    return (
      <div class="container">

        <div class="row">
          <div class="col-md-12">

            <div class="panel panel-primary ">
              <div class="panel-heading">
                <h2 class="panel-title">
                  <div class="align-self-center d-flex justify-content-between">
                    Report
                    <button type="button" class="btn btn-primary no-print" onClick={() => window.print()}>Print Report</button>
                  </div>
                </h2>
              </div>
              <div class="panel-body">
                <br />
                <h4>Country: {user && user.country}</h4>
                <h4>Club Name: {user && user.club ? user.club : "----"}</h4>
                <br />
                <h5>Contact Name: {user && user.contact}</h5>
                <h5>Email: {user && user.email}</h5>
                <br />
                <h5>Address: {user && user.address1}, {user && user.address2}</h5>
                <h5>City: {user && user.city}</h5>
                <h5>Zip Code: {user && user.zip}</h5>
                <br />
              </div>
              <ul class="list-group">

                {athletes && athletes.length > 0 ?
                  <li class="list-group-item">
                    <h4><b>Athletes Categories</b></h4>
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Full Name</th>
                          <th colspan='10' >Categories</th>
                          <th>Price (€)</th>
                        </tr>
                      </thead>
                      {athletes && athletes.map(athlete => {
                        return (
                          <tbody>

                            {athlete.categories && Object.keys(athlete.categories).length > 0 ?
                              <tr>
                                <td>{athlete.name.toUpperCase()} {athlete.surname.toUpperCase()}</td>
                                {Object.keys(athlete.categories)[0] ? <td>{Object.keys(athlete.categories)[0]}</td> : <td> </td>}
                                {Object.keys(athlete.categories)[1] ? <td>{Object.keys(athlete.categories)[1]}</td> : <td> </td>}
                                {Object.keys(athlete.categories)[2] ? <td>{Object.keys(athlete.categories)[2]}</td> : <td> </td>}
                                {Object.keys(athlete.categories)[3] ? <td>{Object.keys(athlete.categories)[3]}</td> : <td> </td>}
                                {Object.keys(athlete.categories)[4] ? <td>{Object.keys(athlete.categories)[4]}</td> : <td> </td>}
                                {Object.keys(athlete.categories)[5] ? <td>{Object.keys(athlete.categories)[5]}</td> : <td> </td>}
                                {Object.keys(athlete.categories)[6] ? <td>{Object.keys(athlete.categories)[6]}</td> : <td> </td>}
                                {Object.keys(athlete.categories)[7] ? <td>{Object.keys(athlete.categories)[7]}</td> : <td> </td>}
                                {Object.keys(athlete.categories)[8] ? <td>{Object.keys(athlete.categories)[8]}</td> : <td> </td>}
                                {Object.keys(athlete.categories)[9] ? <td>{Object.keys(athlete.categories)[9]}</td> : <td> </td>}
                                <td class="text-right">{athlete.categories ? Object.keys(athlete.categories).length * individualPrice : 0}.00</td>
                              </tr>
                              :

                              <tr>
                                <td>{athlete.name.toUpperCase()} {athlete.surname.toUpperCase()}</td>
                                <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
                                <td class="text-right">0.00</td>
                              </tr>}

                            {athlete.categories && athlete.categories.length > 10 ?
                              <tr>
                                <td> </td>
                                {athlete.categories && Object.keys(athlete.categories)[10] ? <td>{Object.keys(athlete.categories)[10]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[11] ? <td>{Object.keys(athlete.categories)[11]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[12] ? <td>{Object.keys(athlete.categories)[12]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[13] ? <td>{Object.keys(athlete.categories)[13]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[14] ? <td>{Object.keys(athlete.categories)[14]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[15] ? <td>{Object.keys(athlete.categories)[15]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[16] ? <td>{Object.keys(athlete.categories)[16]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[17] ? <td>{Object.keys(athlete.categories)[17]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[18] ? <td>{Object.keys(athlete.categories)[18]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[19] ? <td>{Object.keys(athlete.categories)[19]}</td> : <td> </td>}
                                <td class="text-right"> </td>
                              </tr> : ''}

                            {athlete.categories && athlete.categories.length > 20 ?
                              <tr>
                                <td> </td>
                                {athlete.categories && Object.keys(athlete.categories)[20] ? <td>{Object.keys(athlete.categories)[20]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[21] ? <td>{Object.keys(athlete.categories)[21]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[22] ? <td>{Object.keys(athlete.categories)[22]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[23] ? <td>{Object.keys(athlete.categories)[23]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[24] ? <td>{Object.keys(athlete.categories)[24]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[25] ? <td>{Object.keys(athlete.categories)[25]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[26] ? <td>{Object.keys(athlete.categories)[26]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[27] ? <td>{Object.keys(athlete.categories)[27]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[28] ? <td>{Object.keys(athlete.categories)[28]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[29] ? <td>{Object.keys(athlete.categories)[29]}</td> : <td> </td>}
                                <td class="text-right"> </td>
                              </tr> : ''}

                            {athlete.categories && athlete.categories.length > 30 ?
                              <tr>
                                <td> </td>
                                {athlete.categories && Object.keys(athlete.categories)[30] ? <td>{Object.keys(athlete.categories)[30]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[31] ? <td>{Object.keys(athlete.categories)[31]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[32] ? <td>{Object.keys(athlete.categories)[32]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[33] ? <td>{Object.keys(athlete.categories)[33]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[34] ? <td>{Object.keys(athlete.categories)[34]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[35] ? <td>{Object.keys(athlete.categories)[35]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[36] ? <td>{Object.keys(athlete.categories)[36]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[37] ? <td>{Object.keys(athlete.categories)[37]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[38] ? <td>{Object.keys(athlete.categories)[38]}</td> : <td> </td>}
                                {athlete.categories && Object.keys(athlete.categories)[39] ? <td>{Object.keys(athlete.categories)[39]}</td> : <td> </td>}
                                <td class="text-right"> </td>
                              </tr> : ''}
                          </tbody>
                        )
                      })}
                    </table>
                  </li> : ''}

                {pairs && pairs.length > 0 ?
                  <li class="list-group-item">
                    <h4><b>Pair Categories</b></h4>
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Athlete 1</th>
                          <th>Athlete 2</th>
                          <th>Price (€)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pairs && pairs.map(pair => {
                          return (
                            <tr>
                              <td>{pair.category.toUpperCase()}</td>
                              {athletes && athletes.map(athlete => {
                                return (
                                  (athlete.id === pair.athlete1) || (athlete.id === pair.athlete2) ?
                                    <td>{athlete.name.toUpperCase()} {athlete.surname.toUpperCase()}</td> : ''

                                )
                              })}
                              <td class="text-right">{pairPrice}.00</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </li> : ''}

                {teams && teams.length > 0 ?
                  <li class="list-group-item  ">
                    <h4><b>Team Categories</b></h4>
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Athlete 1</th>
                          <th>Athlete 2</th>
                          <th>Athlete 3</th>
                          <th>Price (€)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {teams && teams.map(team => {
                          return (
                            <tr>
                              <td>{team.category.toUpperCase()}</td>
                              {athletes && athletes.map(athlete => {
                                return (
                                  (athlete.id === team.athlete1) || (athlete.id === team.athlete2) || (athlete.id === team.athlete3) ?
                                    <td>{athlete.name.toUpperCase()} {athlete.surname.toUpperCase()}</td> : ''
                                )
                              })}
                              <td class="text-right">{teamPrice}.00</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </li> : ''}

                <li class="list-group-item  ">

                  <div class="row">

                    <div class="col-md-6">
                      <br />
                      <h4><b>Total</b></h4>
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>Categories</th>
                            <th>Price (€)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Indivduals</td>
                            <td class="text-right">{totalIndividualPrice}.00</td>
                          </tr>
                          <tr>
                            <td>Pairs</td>
                            <td class="text-right">{totalPairPrice}.00</td>
                          </tr>
                          <tr>
                            <td>Teams</td>
                            <td class="text-right">{totalTeamPrice}.00</td>
                          </tr>
                          <tr>
                            <td>Merch</td>
                            <td class="text-right">{totalMerchPrice}.00</td>
                          </tr>
                          <tr>
                            <th>Total:</th>
                            <td class="text-right">{totalIndividualPrice + totalPairPrice + totalTeamPrice + totalMerchPrice}.00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </li>

                <li class="list-group-item  ">
                  <br />
                  <div class="alert alert-danger" role="alert">
                    <b>Important: Payment is to be settled before Closing Date : {t('general_closingDate')}.</b>
                  </div>
                  <div class="alert alert-warning" role="alert">
                    Important: When making a payment enter your User ID in the comments.
                    <br />
                    User ID: <b>({this.props.auth.uid})</b>
                  </div>


                  <b><h3>Methods of Payment</h3></b><br />
                  <p>
                    <b><h5>1.	 REVOLUT Card</h5></b>
                    <b>Phone number:</b> +35679537924<br />
                    <b>Username:</b> @christ4hs
                  </p>

                  <p>
                    <b><h5>2.	PAYPAL</h5></b>
                    https://paypal.me/cgalea123
                  </p>
                  <p>
                    <b><h5>3.	 BANK DIRECT TRANSFER</h5></b>
                    {/*<b>Account Name:</b> Mr.C. Galea A/C MKA<br />
                    <b>Account Number:</b> 40013981406<br />
                    <b>IBAN Number:</b> MT48 VALL22013 0000000 40013981406<br />
                    <b>SWIFT/BIC:</b> VALLMTMT<br />
                    <b>Bank Address:</b> Bank of Valletta plc, Racecourse Street, Marsa MRS 1631 Malta
                    <p>
                    <b>Contact Email:</b> detailsmka@gmail.com <br />
                    </p>*/}
                    <p>
                      <b>Account name:</b> MR C. GALEA A/C SKSM SCHOOL <br />
                      <b>Account number:</b> 40019597878
                    </p>
                    <p>
                      <b>IBAN:</b> MT60VALL22013000000040019597878 <br />
                      <b>SWIFT/BIC:</b> VALLMTMT
                    </p>
                    <p>
                      <b>Bank Address:</b> Bank of Valletta plc, Racecourse Street, Marsa MRS1631, Malta <br />
                      <b>Bank sort code:</b> 22-415
                    </p>
                    <p>
                      <b>Contact Email:</b> maltasksm@gmail.com <br />
                    </p>

                  </p>
                  <p>
                    <b>User ID:</b> {this.props.auth.uid}
                  </p>

                </li>


              </ul>
            </div>
          </div>

        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    athletes: state.firestore.ordered[`athletes`] || [],
    auth: state.firebase.auth,
    user: state.firestore.ordered[`user`] || [],
    pairs: state.firestore.ordered[`pairs`] || [],
    teams: state.firestore.ordered[`teams`] || [],
    merch: state.firestore.ordered[`merch`] || [],
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "users",
        doc: props.auth.uid,
        storeAs: `user`
      },
      {
        collection: "users",
        doc: props.auth.uid,
        subcollections: [{ collection: "athletes", orderBy: ['createdAt'] }]
        , storeAs: `athletes`
      },
      {
        collection: "users",
        doc: props.auth.uid,
        subcollections: [{ collection: "teams", orderBy: ['createdAt'] }]
        , storeAs: `teams`
      },
      {
        collection: "users",
        doc: props.auth.uid,
        subcollections: [{ collection: "pairs", orderBy: ['createdAt'] }]
        , storeAs: `pairs`
      },
      {
        collection: "merch",
      },
    ];
  }),
  withTranslation()
)(Report)
