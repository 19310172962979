import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import TeamList from './TeamList'
import AddTeam from './AddTeam'
import EditTeam from './EditTeam'
import categories from "../../categories.json";
import { isAffiliated } from '../../store/actions/otherActions'
import { withTranslation } from 'react-i18next';

class Teams extends Component {

  render() {
    const { location, teams, athletes, auth, t } = this.props;
    var price = teams.length * t('general_teamPrice') * (isAffiliated(auth) ? 8/10 : 1);
    var teamCat = categories.filter(cat => cat.category === "Team");

    return (
      <div className="dashboard container">
        <div className="row">
          <div className="col-md-6">
            <h2>Team Categories</h2>
            <br />
          </div>
          <div className="col-md-6 d-flex flex-row-reverse">
            {(price) ? (price > 0) ? <h2>Total: €{price}.00</h2> : <h2>Total: €--</h2> : <h2>Total: €--</h2>}
          </div>
        </div>

        {athletes.length > 2
          ?
          <div className="row">
            <div className="col s12 m4">
              {location.team
              ? <EditTeam team={location.team} athletes={athletes} teamCat={teamCat} />
              : <AddTeam athletes={athletes} teamCat={teamCat} />
              }
              <br/><div class="alert alert-info" role="alert">Note: Uploaded videos must not exceed 400MB.</div>
            </div>
          </div>
          :
          <div class="alert alert-info" role="alert">Team Registrations cannot be completed. Minimum of 3 athletes needed. Click
            <Link to='/athletes'> here </Link>
            to Register Athletes</div>
        }

        <div className="row">
          <div className="col s12 m6">
            <TeamList teams={teams} athletes={athletes} teamCat={teamCat} auth={auth}/>
          </div>
        </div>

      </div>

    )
  }
}


const mapStateToProps = (state) => {
  return {
    teams: state.firestore.ordered[`teams`] || [],
    athletes: state.firestore.ordered[`athletes`] || [],
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "users",
        doc: props.auth.uid,
        subcollections: [{ collection: "teams", orderBy: ['createdAt'] }]
        , storeAs: `teams`
      },
      {
        collection: "users",
        doc: props.auth.uid,
        subcollections: [{ collection: "athletes", orderBy: ['createdAt'] }]
        , storeAs: `athletes`
      }
    ];
  }),
  withTranslation()
)(Teams)