import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { addTeam, resetErrors } from '../../store/actions/teamActions'
import { validateVideo, validateForm, getAge, capEachLetter, getKataList } from '../../store/actions/otherActions'
import '../../css/funkyradio.css'

class AddTeam extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    state = {
        errors: {
            athlete1: '',
            athlete2: '',
            athlete3: '',
            category: '',
            style: '',
            video: [],
            kata: [],
        },
    }
    handleCancel = (e) => {
        e.preventDefault();
        //this.props.history.push('/registration');
        window.location.reload();
    }
    handleChange = (e) => {
        var { id, value } = e.target;
        const { athletes, teamCat } = this.props;

        this.props.resetErrors();

        let errors = this.state.errors;
        switch (id) {
            case 'style':
                errors.style =
                    value === ""
                        ? 'Select a style!'
                        : '';
                break;
            case 'athlete1':
                errors.athlete1 =
                    value.length === 0
                        ? 'Please choose an athlete!'
                        : '';
                if (value.length !== 0)
                    value = athletes && athletes.find(athlete => value === athlete.id);
                document.getElementById('category').selectedIndex = 0;
                this.setState({ category: "" })
                break;
            case 'athlete2':
                errors.athlete2 =
                    value.length === 0
                        ? 'Please choose an athlete!'
                        : '';
                if (value.length !== 0)
                    value = athletes && athletes.find(athlete => value === athlete.id);
                document.getElementById('category').selectedIndex = 0;
                this.setState({ category: "" })
                break;
            case 'athlete3':
                errors.athlete3 =
                    value.length === 0
                        ? 'Please choose an athlete!'
                        : '';
                if (value.length !== 0)
                    value = athletes && athletes.find(athlete => value === athlete.id);
                document.getElementById('category').selectedIndex = 0;
                this.setState({ category: "" })
                break;
            case 'category':
                if (value === "") {
                    errors.category = 'Select a category!';
                } else {
                    errors.category = '';
                    value = teamCat.find(cat => cat.num === value);
                    var array = [];
                    for (var i = 0; i < value.rounds; i++) {
                        array[i] = "";
                    }
                    errors.video = array.slice();
                    errors.kata = array.slice();
                    this.setState({ kata: array.slice(), video: array.slice() })
                }
                break;
            default:
                break;
        }
        this.setState({ errors, [id]: value })

    }
    handleKataChange = (e) => {
        var { id, value } = e.target;

        let errors = this.state.errors;
        let kata = this.state.kata;

        var index = id.includes("kata") ? id.match(/\d+/)[0] : -1;
        errors.kata[index] =
            value.length <= 0
                ? 'Select a kata!'
                : '';

        kata[index] = value;
        this.setState({ errors, kata: kata })
    }
    handleVideoButton = (index, e) => {
        e.preventDefault();

        this.props.resetErrors();
        const fileInput = document.getElementById("video" + index);
        fileInput.click();

    }

    handleUploadVideo = (e) => {
        const { id } = e.target;

        //this.props.resetErrors();

        let errors = this.state.errors;
        let video = this.state.video ? this.state.video : [];
        let isVideo = (e.target.files[0]) ? validateVideo(e.target.files[0]) : false;
        let index = id.match(/\d+/)[0];

        if (isVideo && e.target.files[0]['size'] > 419430400) {
            errors.video[index] = 'Video must be less than 400MB';
        } else if (!isVideo) {
            errors.video[index] = 'File must be a video!';
        } else {
            errors.video[index] = '';
            video[index] = e.target.files[0];
        }

        this.setState({ errors, video: video });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = this.state.errors;

        if (document.getElementById("athlete1").value.trim() === "") {
            errors.athlete1 = 'Please choose an athlete!';
        }
        if (document.getElementById("athlete2").value.trim() === "") {
            errors.athlete2 = 'Please choose an athlete!';
        }
        if (document.getElementById("athlete3").value.trim() === "") {
            errors.athlete3 = 'Please choose an athlete!';
        }
        if (!this.state.category || this.state.category === null) {
            errors.category = 'Category required!';
        }
        if (this.state.category.sport === "Karate" && (!this.state.style || this.state.style === "")) {
            errors.style = 'Select a style!';
        }
        if (errors.category === "") {
            for (var i = 0; i < this.state.category.rounds; i++) {
                if (!this.state.kata || !this.state.kata[i] || this.state.kata[i] === "") {
                    errors.kata[i] = 'Select a kata!';
                }
                if (!this.state.video || !this.state.video[i] || this.state.video[i] === "") {
                    errors.video[i] = 'Upload a video!';
                }
            }
        }

        this.setState({ errors })

        if (validateForm(this.state.errors)) {
            this.props.addTeam(this.state, this.props.history);
            document.getElementById("form").reset();
            var array = [];
            for (var i = 0; i < this.state.category.rounds; i++) {
                array[i] = "";
            }
            this.setState({ category: '', style: '', athlete1: '', athlete2: '', athlete3: '', video: array.slice(), kata: array.slice() })
        } else {
            console.error('Invalid Form')
        }
    }

    componentWillUnmount() {
        this.props.resetErrors();
    }
    render() {
        const { addTeamError, addTeamSuccess, athletes, teamCat, styles } = this.props;
        return (
            <div class="col-12-md">
                <div class="card card-inverse card-info">
                    <form id="form" onSubmit={this.handleSubmit}>
                        <br /><h3 class="">Add Team</h3>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label htmlFor="athlete1">Athlete 1*</label>
                                <select class="form-control" id="athlete1" onChange={this.handleChange}>
                                    <option value=''>-Select Athlete-</option>
                                    {athletes && athletes.map(athlete => {
                                        return (
                                            ((this.state.athlete2 && this.state.athlete2.id === athlete.id)
                                                || (this.state.athlete3 && this.state.athlete3.id === athlete.id)) ? '' :
                                                <option value={athlete.id}>{athlete.name} {athlete.surname}</option>
                                        )
                                    })}
                                </select>
                                {this.state.errors.athlete1.length > 0 && <span className='error'>{this.state.errors.athlete1}</span>}
                            </div>
                            <div class="form-group col-md-4">
                                <label htmlFor="athlete2">Athlete 2*</label>
                                <select class="form-control" id="athlete2" onChange={this.handleChange}>
                                    <option value=''>-Select Athlete-</option>
                                    {athletes && athletes.map(athlete => {
                                        return (
                                            ((this.state.athlete1 && this.state.athlete1.id === athlete.id)
                                                || (this.state.athlete3 && this.state.athlete3.id === athlete.id)) ? '' :
                                                <option value={athlete.id}>{athlete.name} {athlete.surname}</option>
                                        )
                                    })}
                                </select>
                                {this.state.errors.athlete2.length > 0 && <span className='error'>{this.state.errors.athlete2}</span>}
                            </div>
                            <div class="form-group col-md-4">
                                <label htmlFor="athlete3">Athlete 3*</label>
                                <select class="form-control" id="athlete3" onChange={this.handleChange}>
                                    <option value=''>-Select Athlete-</option>
                                    {athletes && athletes.map(athlete => {
                                        return (
                                            ((this.state.athlete1 && this.state.athlete1.id === athlete.id)
                                                || (this.state.athlete2 && this.state.athlete2.id === athlete.id)) ? '' :
                                                <option value={athlete.id}>{athlete.name} {athlete.surname}</option>
                                        )
                                    })}
                                </select>
                                {this.state.errors.athlete3.length > 0 && <span className='error'>{this.state.errors.athlete3}</span>}
                            </div>
                        </div>
                        <div class="form-group">
                            <label htmlFor="category">Category*</label>
                            <select class="form-control" id="category" onChange={this.handleChange}>
                                <option value="">-- Select an Option --</option>
                                {teamCat && teamCat.map(cat => {
                                    var maxAge = this.state.athlete1 && this.state.athlete2 && this.state.athlete3
                                    ? Math.max(Math.max(getAge(this.state.athlete1.dob), getAge(this.state.athlete2.dob)), getAge(this.state.athlete3.dob))
                                    : 0;
                                    return (
                                        (this.state.athlete1 && this.state.athlete2 && this.state.athlete3 &&
                                            ((cat.sex === "B") || (this.state.athlete1.sex === cat.sex && this.state.athlete2.sex === cat.sex && this.state.athlete3.sex === cat.sex) || (cat.sex === "X" && (this.state.athlete1.sex !== this.state.athlete2.sex || this.state.athlete1.sex !== this.state.athlete3.sex || this.state.athlete2.sex !== this.state.athlete3.sex))) &&
                                            ((getAge(this.state.athlete1.dob) >= cat.minAge && getAge(this.state.athlete1.dob) <= cat.maxAge) &&
                                            (getAge(this.state.athlete2.dob) >= cat.minAge && getAge(this.state.athlete2.dob) <= cat.maxAge) &&
                                            (getAge(this.state.athlete3.dob) >= cat.minAge && getAge(this.state.athlete3.dob) <= cat.maxAge)))
                                            ? <option value={cat.num}>{cat.num} - {cat.sport} {cat.name} {cat.type}</option>
                                            : ''
                                    )
                                })}
                            </select>
                            {this.state.errors.category.length > 0 && <span className='error'>{this.state.errors.category}</span>}
                        </div>

                        {this.state.category && this.state.category.sport === "Karate" ?
                            <div class="form-group">
                                <label htmlFor="style">Style*</label>
                                <select class="form-control" id="style" onChange={this.handleChange}>
                                    <option value="">-- Select an Option --</option>
                                    {styles && styles.map(style => {
                                        return (
                                            <option value={style.id}>{capEachLetter(style.id)}</option>
                                        )
                                    })}
                                </select>
                                {this.state.errors.style.length > 0 && <span className='error'>{this.state.errors.style}</span>}
                            </div>
                            : ""}

                        {this.state.category
                            && ((this.state.style && this.state.category.sport === "Karate") || this.state.category.sport !== "Karate")
                            && this.state.kata && this.state.kata.map((x, index) => {
                                return (
                                    <div class="form-row">
                                        <label>Round {index + 1}</label>
                                        <div class="form-group col-md-12">
                                            <label for={"kata" + index}>Name of Kata</label>
                                            {this.state.category.sport === "Karate" ?
                                                <select class="form-control" id={"kata" + index} onChange={this.handleKataChange}>
                                                    <option value="">-- Select an Option --</option>
                                                    {styles && this.state.style && getKataList(styles.find(s => s.id === this.state.style)).map(kata => {
                                                        return (
                                                            <option value={kata}>{capEachLetter(kata)}</option>
                                                        )
                                                    })}
                                                </select>
                                                :
                                                <input class="form-control" id={"kata" + index} onChange={this.handleKataChange} />}
                                            {this.state.errors.kata[index] && <span className='error'>{this.state.errors.kata[index]}</span>}
                                        </div>
                                        <div class="form-group col-md-12">

                                            <button type="button" class="btn btn-primary" onClick={this.handleVideoButton.bind(this, index)}>Upload Videos</button>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id={"video" + index}
                                                hidden="hidden"
                                                onChange={this.handleUploadVideo}
                                            />
                                        &nbsp;&nbsp;<span>{this.state.video[index].name ? this.state.video[index].name : "Not Uploaded"}</span>
                                            <br />
                                            {this.state.errors.video[index] && <span className='error'>{this.state.errors.video[index]}</span>}
                                        </div>

                                    </div>

                                )
                            })}

                        <button type="submit" class="btn btn-primary">Save</button>
                        &nbsp;
                        <button type="button" class="btn btn-secondary" onClick={this.handleCancel}>Cancel</button>
                        {addTeamError ? <div class="alert alert-danger" role="alert">{addTeamError}</div> : null}
                        {addTeamSuccess ? <div class="alert alert-success" role="alert">{addTeamSuccess}</div> : null}
                    </form>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addTeamError: state.team.addTeamError,
        addTeamSuccess: state.team.addTeamSuccess,
        auth: state.firebase.auth,
        user: state.firestore.ordered.users && state.firestore.ordered.users.find(element => {
            return element.id === state.firebase.auth.uid
        }),
        styles: state.firestore.ordered[`styles`] || [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addTeam: (registration, history) => dispatch(addTeam(registration, history)),
        resetErrors: () => dispatch(resetErrors())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => {
        return [
            {
                collection: "styles"
            }
        ];
    })
)(AddTeam);