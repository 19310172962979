export const validateForm = (errors) => {
    for (const err in errors) {
        var val = errors[err];
        if (Array.isArray(val)) {
            for (var i = 0; i < val.length; i++)
                if (val[i] !== "") return false;
        } else {
            if (val.length > 0) return false;
        }
    }
    return true;
};
export const capEachLetter = (mySentence) => {
    const words = mySentence.split(" ");

    return words.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
    }).join(" ");

};
export const isAffiliated = (user) => {

    if (!user || !user.email) return false;

    switch (user.email) {
        case "jairzinhozammit@emails":
            return true;
        default:
            return false;
    }
};
export const getKataList = (array) => {
    return array.sentei.concat(array.shitei).concat(array.tokui);
}
export const validateImage = (image) => {
    let type = image.type ? image.type : 'name';
    return type.includes('image');

};
export const validateVideo = (video) => {
    let type = video.type ? video.type : 'name';
    return type.includes('video');

};
export const saveReference = (document, docReference, name) => {
    var obj = {};
    obj[name] = docReference;

    document.update({
        ...obj,
    })

};

export const saveVideoReference = (document, docReference, category) => {
    document.set({
        categories: { [category]: docReference }
    }, { merge: true })
};

export const uploadImage = (location, image, name, firebase, document) => {
    // make async call to database

    const storageRef = firebase.storage().ref();

    var metadata = {
        contentType: 'image/jpeg'
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child(location + '/' + name).put(image, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    break;
            }
        }, function (error) {

            console.log("error");
        }, function () {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                saveReference(document, downloadURL, name);
            });

        });

};

export const uploadProof = (location, proof, name, firebase, doc) => {
    // make async call to database

    const storageRef = firebase.storage().ref();

    var metadata = {
        contentType: proof.type
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child(location + '/' + name).put(proof, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    break;
            }
        }, function (error) {

            console.log("error");
        }, function () {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                doc.set({
                    proof: downloadURL
                }, { merge: true });
            });
        });

};

export const uploadVideo = (location, category, video, firebase, document, dispatch) => {
    // make async call to database

    const storageRef = firebase.storage().ref();

    var metadata = {
        contentType: 'video/mp4'
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child(location + '/' + category).put(video, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch({ type: 'UPLOAD', progress });
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    break;
            }
        }, function (error) {

            console.log("error");
        }, function () {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                saveVideoReference(document, downloadURL, category);

            });

        });

};


export const getAge = (dateString) => {

    var today = new Date('2022-03-11');
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};