import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

class DeleteRedirect extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { a } = this.props;
    if (!a) return <Redirect to='/registration' />
    return (
      <div className="dashboard container">
        
      </div>
    )
  }
}


export default DeleteRedirect;