import React, { Component } from 'react'
import AthleteSummary from './AthleteSummary'

class AthleteList extends Component {

    render() {

        const { athletes } = this.props;

        return (
            <div className="row">
                {athletes && athletes.map(athlete => {
                    return (
                        <AthleteSummary athlete={athlete} />
                    )
                })}
            </div>
        )
    }
}

export default AthleteList;
