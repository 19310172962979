import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import AthleteList from './AthleteList'
import AddAthlete from './AddAthlete'
import EditAthlete from './EditAthlete'
import { withTranslation } from 'react-i18next'

class Athletes extends Component {
  render() {
    const { location, athletes } = this.props;

    return (
      <div className="dashboard container">
        <div className="row">
          <div className="col s12 m4">
            {location.athlete
              ? <EditAthlete athlete={location.athlete} />
              : <AddAthlete />
            }
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6">
            <AthleteList athletes={athletes} />
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    athletes: state.firestore.ordered[`athletes`] || [],
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  firestoreConnect(props => {
      return [
          {
              collection: "users",
              doc: props.auth.uid,
              subcollections: [{ collection: "athletes", orderBy: ['createdAt'] }]
              , storeAs: `athletes`
          }
      ];
  })
)(Athletes)
