import React from 'react'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Navigation = (props) => {
  const { t } = useTranslation();

  const { auth, profile } = props;
  const links = auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />;
  return (


    <nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-brand">
        <img class="logo" alt="" src={process.env.PUBLIC_URL + '/logo.png'} />
        <Link to='/' class="navbar-brand"><h4>&nbsp;&nbsp;{t('shortName')}</h4></Link>
      </div>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        {links}
      </div>
    </nav>


  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps)(Navigation)
