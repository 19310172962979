import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const SignedOutLinks = (props) => {
  const { t } = props;

  return (
    <ul class="navbar-nav ml-auto">

      <li class="nav-item">
        <a href={t("nav_homeLink")} class="nav-link">{t("nav_homeWebsite")}&nbsp;&nbsp;</a>
      </li>
      <li class="nav-item">
        <Link to='/signup' class="nav-link">Register&nbsp;&nbsp;</Link>
      </li>
      <li class="nav-item">
        <Link to='/signin' class="nav-link">Log In&nbsp;&nbsp;</Link>
      </li>
    </ul>

  )
}

export default withTranslation()(SignedOutLinks)