import React, { Component } from 'react'
import { connect } from 'react-redux'
import { forgotPassword } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'

class ForgetPassword extends Component {
  state = {
    email: '',
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.forgotPassword(this.state)
  }
  render() {
    const { passError, passSuccess, auth } = this.props;
    if (auth.uid) return <Redirect to='/' />
    return (
      <main class="login-form">
          <div class="cotainer">
            <div class="row justify-content-center">
              <div class="col-md-8">
                <div class="card">
                  <div class="card-header"><h3>Reset Password</h3></div>
                  <div class="card-body">
                    <form onSubmit={this.handleSubmit}>
                      <div class="form-group row">
                        <label htmlFor="email_address" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>
                        <div class="col-md-6">
                          <input type="email" id='email' class="form-control" required autofocus onChange={this.handleChange} />
                        </div>
                      </div>

                      <div class="col-md-6 offset-md-4">
                        <button refs={btn => { this.btn = btn; }}  type="submit cooldown" class="btn btn-primary">Reset Password</button>
                      </div>

                      {passError ? <div class="alert alert-danger" role="alert">{passError}</div> : null}
                      {passSuccess ? <Redirect to='/signin' /> : null}

                    </form>
                  </div>

                </div>

              </div>
            </div>
          </div>
      </main>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    passError: state.auth.passError,
    passSuccess: state.auth.passSuccess,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (creds) => dispatch(forgotPassword(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)