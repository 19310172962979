import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import IndividualList from './IndividualList'
import categories from "../../categories.json";
import { isAffiliated } from '../../store/actions/otherActions'
import { withTranslation } from 'react-i18next';

class Individuals extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { athletes, t } = this.props;
    var price = athletes.reduce((a, b) => a + ((b['categories'] ? Object.keys(b['categories']).length : 0) || 0), 0) * t('general_individualPrice') * (isAffiliated(this.props.auth) ? 8/10 : 1);
    var individualCat = categories.filter(cat => cat.category === "Individual");

    return (
      <div className="dashboard container">
        <div className="row">
          <div className="col-md-6">
            <h2>Category Registration</h2>
          </div>
          <div className="col-md-6 d-flex flex-row-reverse">
            {(price) ? (price > 0) ? <h2>Total: €{price}.00</h2> : <h2>Total: €--</h2> : <h2>Total: €--</h2>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <br />
            <div class="alert alert-danger ">
              <b>
                - MALTA OPEN ONLINE 2022 Registration will be accepted ONLY if Payment is settled before Closing Date : 20th Febuary 2022.<br />
                - Refer to Payment Details in the REPORT LINK (on this page).<br />
                - If Payment is NOT effected, your entry will be Rejected and your participation in the MALTA OPEN ONLINE will NOT be Included.
              </b>
            </div>
            <IndividualList athletes={athletes} individualCat={individualCat} />
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    athletes: state.firestore.ordered[`athletes`] || [],
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "users",
        doc: props.auth.uid,
        subcollections: [{ collection: "athletes", orderBy: ['createdAt'] }]
        , storeAs: `athletes`
      }
    ];
  }),
  withTranslation()
)(Individuals)
