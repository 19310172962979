const initState = {
  clubError: null,
  clubSuccess: null,
}

const clubReducer = (state = initState, action) => {
    switch (action.type) {
        case 'EDIT_CLUB_SUCCESS':
            return {...state,
              clubError: null,
              clubSuccess: 'Club Details Updated'
            };
        case 'EDIT_CLUB_ERROR':
          return {...state,
            clubError: action.err.message,
            clubSuccess: null
          };
          case 'RESET':
            return {
              ...initState
            };
        default:
            return state;
      }
};

export default clubReducer; 