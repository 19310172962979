import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

const SignedInLinks = (props) => {
  const { t } = props;

  return (
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <Link to='/' class="nav-link">Registration Home&nbsp;&nbsp;</Link>
      </li>
      <li class="nav-item">
        <a href={t("nav_homeLink")} class="nav-link">{t("nav_homeWebsite")}&nbsp;&nbsp;</a>
      </li>
      <li class="nav-item">
        <Link to='/' class="nav-link" onClick={props.signOut}>Log Out&nbsp;&nbsp;</Link>
      </li>
    </ul>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps))(SignedInLinks)