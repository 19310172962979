import { useTranslation } from 'react-i18next';


export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password,
    ).then(() => {
      window.location.reload();
      dispatch({ type: 'LOGIN_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err });
    });

  }
}

export const sendEmail = (credentials) => {
  return async (dispatch, getState, { getFirebase }) => {
    
    window.emailjs.send("gmail", "template_bD9bMd80", { "email": credentials.email, "name": credentials.name, "country": credentials.country, "contact": credentials.contact, "website": credentials.website })
      .then(() => {
        dispatch({ type: 'REG_SUCCESS' });
      }).catch((err) => {
        dispatch({ type: 'REG_ERROR', err });
      });

  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signOut().then(() => {
      dispatch({ type: 'SIGNOUT_SUCCESS' })
    });
  }
}

export const signUp = (newUser, d) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.auth().createUserWithEmailAndPassword(
      newUser.email,
      newUser.password
    ).then(resp => {
      return firestore.collection('users').doc(resp.user.uid).set({
        ...newUser,
        errors: getFirebase().firestore.FieldValue.delete(),
        password: getFirebase().firestore.FieldValue.delete(),
        confirmPassword: getFirebase().firestore.FieldValue.delete() 
      }, { merge: true });
    }).then(() => {
      dispatch({ type: 'SIGNUP_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'SIGNUP_ERROR', err });
    });

  }
}


export const forgotPassword = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().sendPasswordResetEmail(
      credentials.email
    ).then(() => {
      dispatch({ type: 'FORGET_PASSWORD_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'FORGET_PASSWORD_ERROR', err });
    });

  }
}

export const verifyEmail = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().currentUser.sendEmailVerification()
      .then(() => {
        dispatch({ type: 'VERIFY_SUCCESS' });
      }).catch((err) => {
        dispatch({ type: 'VERIFY_ERROR', err });
      });

  }
}

export const resetErrors = () => {
  return (dispatch) => {
    dispatch({ type: 'RESET' });
  }
};