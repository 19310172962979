import React, { Component } from 'react'

class Disclamer extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {

        return (
            <div class="col-md-12">
                <hr />
                <h1><b>Applications Closed </b></h1>
                <hr />
            </div>

        )
    }
}

export default Disclamer;