const initState = {
  signinError: null,
  signinSuccess: null,
  signupError: null,
  signupSuccess: null,
  passError: null,
  passSuccess: null,
  verifySuccess: null,
  verifyError: null
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      console.log('login error');
      return {
        ...state,
        signinError: action.err.message,
        signinSuccess: null,
        signupError: null,
        signupSuccess: null,
        passError: null,
        passSuccess: null
      }
    case 'LOGIN_SUCCESS':
      console.log('login success');
      return {
        ...state,
        signinError: null,
        signinSuccess: null,
        signupError: null,
        signupSuccess: null,
        passError: null,
        passSuccess: null
      }
      case 'REG_ERROR':
      console.log('login error');
      return {
        ...state,
        signinError: action.err.message,
        signinSuccess: null,
        signupError: null,
        signupSuccess: null,
        passError: null,
        passSuccess: null
      }
    case 'REG_SUCCESS':
      console.log('login success');
      return {
        ...state,
        signinError: null,
        signinSuccess: "A registration request has been successfully sent. Please wait for a reply to your email.",
        signupError: null,
        signupSuccess: null,
        passError: null,
        passSuccess: null
      }
    case 'SIGNOUT_SUCCESS':
      console.log('signout success');
      return state
    case 'SIGNUP_SUCCESS':
      console.log('signup success')
      return {
        ...state,
        signinError: null,
        signinSuccess: null,
        signupError: null,
        signupSuccess: null,
        passError: null,
        passSuccess: null
      }
    case 'SIGNUP_ERROR':
      console.log('signup error: '+ action.err.message)
      return {
        ...state,
        signinError: null,
        signinSuccess: null,
        signupError: action.err.message,
        signupSuccess: null,
        passError: null,
        passSuccess: null
      }
    case 'PASSWORD_ERROR':
      console.log('signup error')
      return {
        ...state,
        signinError: null,
        signinSuccess: null,
        signupError: null,
        signupSuccess: null,
        passError: action.passError,
        passSuccess: null
      }

    case 'FORGET_PASSWORD_SUCCESS':
        return {
        ...state,
        signinError: null,
        signinSuccess: 'Password reset email sent',
        signupError: null,
        signupSuccess: null,
        passError: null,
        passSuccess: null
      }
    case 'FORGET_PASSWORD_ERROR':
      return {
        ...state,
        signinError: null,
        signinSuccess: null,
        signupError: null,
        signupSuccess: null,
        passError: action.err.message,
        passSuccess: null
      }
      case 'VERIFY_SUCCESS':
        return {
        ...state,
        verifySuccess: 'A verification link has been sent to your email account',
        verifyError: null
      }
    case 'VERIFY_ERROR':
      return {
        ...state,
        verifySuccess: null,
        verifyError: action.err.message
      }
      case 'RESET':
      return {
        ...initState
      }
    default:
      return state
  }
};

export default authReducer; 