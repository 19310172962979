import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../../css/sidebar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { withTranslation } from 'react-i18next';

class Sidebar extends Component {

  handleClick = (s, e) => {
    var a = document.getElementsByTagName('li')
    for (var i = 0; i < a.length; i++) {
      a[i].classList.remove('active');
    }
    document.getElementById(s).classList.add('active');
    this.props.history.push('/' + s);
  }
  render() {
    const { t } = this.props;

    return (
      <div id="sidebar" class="well sidebar-nav no-print">
        <div class="social-container">
          <center>
            <a href={t('link_social_youtube')} className="youtube social" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>&nbsp;&nbsp;&nbsp;
              <a href={t('link_social_facebook')} className="facebook social" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>&nbsp;&nbsp;&nbsp;&nbsp;
              <a href={t('link_social_twitter')} className="twitter social" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>&nbsp;&nbsp;&nbsp;&nbsp;
              <a href={t('link_social_instagram')} className="instagram social" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </center>
        </div>
        <hr />
        <i class="fa fa-home"></i><b>&nbsp;Home</b>
        <ul class="sidemenu sidemenu-pills sidemenu-stacked">
          <li id="info"><button class="btn btn-link" onClick={this.handleClick.bind(this, "info")}>Information</button></li>
          <li id="details"><button class="btn btn-link" onClick={this.handleClick.bind(this, "details")}>Details / Address</button></li>
        </ul>
        <div>
          <hr />
          <i class="fa fa-users"></i><b>&nbsp;REGISTRATIONS</b>
          <ul class="sidemenu sidemenu-pills sidemenu-stacked">
            <li id="athletes"><button class="btn btn-link" onClick={this.handleClick.bind(this, "athletes")}>Data / Information</button></li>
          </ul>
        </div>

      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    user: state.firestore.ordered[`user`] || [],
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  withTranslation()
)(Sidebar)