import { uploadImage, uploadProof } from './otherActions'

export const addAthlete = (athlete, image, compID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const firebase = getFirebase();
        const storageRef = firebase.storage().ref();
        const authorId = getState().firebase.auth.uid;

        const doc = firestore.collection('users').doc(authorId).collection('athletes').doc();
        const video = {
            video1: athlete.video1,
            video2: athlete.video2
        };
        delete athlete.video1;
        delete athlete.video2;

        doc.set({
            ...athlete,
            errors: getFirebase().firestore.FieldValue.delete(),
            createdAt: new Date()
        }, { merge: true }).then(() => {
            image && uploadImage(authorId + "/" + doc.id, image, "image", getFirebase(), doc);

            // Upload file and metadata to the object 'images/mountains.jpg'
            addVideo(dispatch, firebase, storageRef, authorId, doc, video, "video1", 0, 50);

            //dispatch({ type: 'ADD_ATHLETE_SUCCESS', athlete });
        }).catch(err => {
            dispatch({ type: 'ADD_ATHLETE_ERROR', err });
        });
    }
};

export const addVideo = (dispatch, firebase, storageRef, authorId, doc, video, name, startPer, endPer) => {
    // make async call to database

    var metadata = {
        contentType: 'video/mp4'
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child(authorId + "/" + doc.id + '/' + name).put(video[name], metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * (endPer-startPer)) + startPer;
            dispatch({ type: 'UPLOAD', progress });
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    break;
            }
        }, function (error) {
            console.log("error");
        }, function () {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                doc.set({[name]: downloadURL}, { merge: true }).then(() => {
                    if (endPer != 100) {
                        addVideo(dispatch, firebase, storageRef, authorId, doc, video, "video2", 50, 100);
                    }
                    /*else {
                        dispatch({ type: 'RESET_REG' });
                        history.push('/registration');
                    }*/
                });

            });

        });

};

export const deleteAthlete = (athlete, compID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid;

        const doc = firestore.collection('users').doc(authorId).collection('athletes').doc(athlete.id);

        doc.delete().then(function () {
            console.log("Document successfully deleted!");
            getFirebase().storage().ref().child(`${authorId}/${athlete.id}/image`).delete();
            getFirebase().storage().ref().child(`${authorId}/${athlete.id}/video1`).delete();
            getFirebase().storage().ref().child(`${authorId}/${athlete.id}/video2`).delete();
        }).catch(function (error) {
            console.error("Error removing document: ", error);
        });

    }
};

export const editAthlete = (athlete, image, id, compID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid;

        const doc = firestore.collection('users').doc(authorId).collection('athletes').doc(id);

        doc.update({
            ...athlete,
            categories: {},
            errors: getFirebase().firestore.FieldValue.delete()
        }, { merge: true }).then(() => {
            image && uploadImage(authorId + "/" + id, image, "image", getFirebase(), doc);
            dispatch({ type: 'EDIT_ATHLETE_SUCCESS', athlete });

        }).catch(err => {
            dispatch({ type: 'EDIT_ATHLETE_ERROR', err });
        });
    }
};


export const resetErrors = () => {
    return (dispatch) => {
        dispatch({ type: 'RESET' });
    }
};