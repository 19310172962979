import React, { Component } from 'react'
import { connect } from 'react-redux'
import { verifyEmail } from '../../store/actions/authActions'

class VerifyEmail extends Component {
  handleClick = (e) => {
    e.preventDefault();
    this.props.verifyEmail()
  }
  handleRoute = (e) => {
    e.preventDefault();
    this.props.history.push('/')
  }
  render() {
    const { verifyError, verifySuccess, auth } = this.props;
    return (
      <main class="login-form">
          <div class="cotainer">
            <div class="row justify-content-center">
              <div class="col-md-8">
                <div class="card">
                  <div class="card-header"><h3>Verify Email</h3></div>
                  <div class="card-body">

                    <div class="col-md-6 offset-md-3">
                      <center>
                        <p>Thank you for signing up! We just need to verify your email address to complete setting up your account.</p>
                        <small>Click the button below to send a verification link to your email.</small>
                        <br /><br />
                        <button onClick={this.handleClick} type="cooldown" class="btn btn-primary">Send Verification Email</button>
                        <br /><br />
                        {auth.emailVerified === false && <b><span>Status: </span><span className='error'>Not Verified</span></b>}
                        <br />
                        <button onClick={() => window.location.reload(false)} class="btn btn-secondary">Check Verification</button>
                        </center>
                    </div>
                    <br />
                    {verifyError ? <div class="alert alert-danger" role="alert">{verifyError}</div> : null}
                    {verifySuccess ? <div class="alert alert-success" role="alert">{verifySuccess}<br />{auth.email}</div> : null}

                  </div>
                </div>
              </div>
            </div>
          </div>
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    verifyError: state.auth.verifyError,
    verifySuccess: state.auth.verifySuccess,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    verifyEmail: () => dispatch(verifyEmail())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)