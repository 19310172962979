import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { addRegistration, resetErrors } from '../../store/actions/registrationActions'
import { validateForm, getAge } from '../../store/actions/otherActions'
import '../../css/funkyradio.css'

class EditRegistration extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    state = {
        errors: {
            categories: ''
        },
        categories: []
    }
    handleRadioChange = (e) => {
        const { name, value, checked } = e.target;
        var arr = this.state.categories;
        let errors = this.state.errors;

        if (checked) {
            arr.push(value)
            errors.categories = '';
        } else {

            for (var i = 0; i < arr.length; i++) {
                if (arr[i] === value) {
                    arr.splice(i, 1);
                }
            }
            if (arr.length === 0) errors.categories = 'Select Categories!';

        }
        arr.sort((a, b) => a.match(/(\d+)/)[0] - b.match(/(\d+)/)[0]);

        this.setState({ errors, [name]: arr })
    }
    handleSubmit = (e) => {

        e.preventDefault();
        let errors = this.state.errors;

        if (this.state.categories.length < 1) {
            errors.categories = 'Select Categories!';
        }
        this.setState({ errors })

        if (validateForm(this.state.errors)) {

            this.props.addRegistration(this.state, this.props.athlete.id);
            document.getElementById("form").reset();
            this.setState({ categories: [] })
            this.props.history.push('/individuals');

        } else {
            console.error('Invalid Form')
        }

    }
    handleCancel = (e) => {
        this.props.history.push('/individuals');
    }
    componentWillUnmount() {
        this.props.resetErrors();
        
    }
    componentWillMount() {

        this.setState({ categories: this.props.athlete.categories })
    }
    render() {
        const { athlete, individualCat } = this.props;

        var gradeMap = new Map();

        // setting the values
        gradeMap.set('Novice (white belt)', 10);
        gradeMap.set('9th Kyu', 9);
        gradeMap.set('8th Kyu', 8);
        gradeMap.set('7th Kyu', 7);
        gradeMap.set('6th Kyu', 6);
        gradeMap.set('5th Kyu', 5);
        gradeMap.set('4th Kyu', 4);
        gradeMap.set('3rd Kyu', 3);
        gradeMap.set('2nd Kyu', 2);
        gradeMap.set('1st Kyu', 1);
        gradeMap.set('1st Dan', -1);
        gradeMap.set('2nd Dan', -2);
        gradeMap.set('3rd Dan', -3);
        gradeMap.set('4th Dan', -4);
        gradeMap.set('5th Dan', -5);
        gradeMap.set('6th Dan', -6);
        gradeMap.set('7th Dan', -7);
        gradeMap.set('8th Dan', -8);

        return (
            <div class="col-12-md">
                <div class="card card-inverse card-info">
                    <form id="form" onSubmit={this.handleSubmit}>
                        <br /><h3 class="">Edit Individual Categories for {athlete.name} {athlete.surname}</h3>

                        <div class="form-group">
                            <label htmlFor="categories">Category*</label>
                            <div id="categories" class="funkyradio" onChange={this.handleRadioChange}>
                                <label>&nbsp;Kata</label>
                                {individualCat && individualCat.map(cat => {
                                    return (
                                        (cat.type === "KATA") &&
                                            (athlete.sex === cat.sex || cat.sex === 'B') &&
                                            (gradeMap.get(athlete.grade) >= cat.maxKyu) &&
                                            ((getAge(athlete.dob) >= 36 && getAge(athlete.dob) >= cat.minAge && cat.maxAge > 20) ||
                                                (getAge(athlete.dob) < 36 && getAge(athlete.dob) <= cat.maxAge && cat.section !== 'MASTERS'))
                                            ?
                                            <div class="funkyradio-success">
                                                <input defaultChecked={this.state.categories.includes(cat.id.toString())} class="form-control" type="checkbox" name="categories" value={cat.num} id={cat.id} />
                                                <label for={cat.id}>{cat.num} - {cat.name}</label>
                                            </div> : ''
                                    )
                                })}

                                <label>&nbsp;Kumite</label>
                                {individualCat && individualCat.map(cat => {
                                    return (
                                        (cat.type === "KUMITE") &&
                                            (athlete.sex === cat.sex || cat.sex === 'B') &&
                                            (gradeMap.get(athlete.grade) >= cat.maxKyu) &&
                                            ((cat.weight <= athlete.weight && cat.direction === 'PLUS') ||
                                                (cat.weight >= athlete.weight)) &&
                                                ((getAge(athlete.dob) >= 36 && getAge(athlete.dob) >= cat.minAge && cat.maxAge > 20) ||
                                                (getAge(athlete.dob) < 36 && getAge(athlete.dob) <= cat.maxAge && cat.section !== 'MASTERS'))

                                            ?
                                            <div class="funkyradio-success">
                                                <input defaultChecked={this.state.categories.includes(cat.id.toString())} class="form-control" type="checkbox" name="categories" value={cat.num} id={cat.id} />
                                                <label for={cat.id}>{cat.num} - {cat.name}</label>
                                            </div> : ''
                                    )
                                })}
                                <label>&nbsp;Kobudo</label>
                                {individualCat && individualCat.map(cat => {
                                    return (
                                        (cat.type === "KOBUDO") &&
                                            (athlete.sex === cat.sex || cat.sex === 'B') &&
                                            ((getAge(athlete.dob) >= 36 && getAge(athlete.dob) >= cat.minAge && cat.maxAge > 20) ||
                                                (getAge(athlete.dob) < 36 && getAge(athlete.dob) <= cat.maxAge && cat.minAge < 36))

                                            ?
                                            <div class="funkyradio-success">
                                                <input defaultChecked={this.state.categories.includes(cat.id.toString())} class="form-control" type="checkbox" name="categories" value={cat.num} id={cat.id} />
                                                <label for={cat.id}>{cat.num} - {cat.name}</label>
                                            </div> : ''
                                    )
                                })}
                            </div>
                            {this.state.errors.categories.length > 0 && <span className='error'>{this.state.errors.categories}</span>}
                        </div>
                        <button type="submit" class="btn btn-primary">Save Changes</button>
                        &nbsp;
                        <button type="button" class="btn btn-secondary" onClick={this.handleCancel}>Cancel</button>
                    </form>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        user: state.firestore.ordered.users && state.firestore.ordered.users.find(element => {
            return element.id === state.firebase.auth.uid
        })
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addRegistration: (registration, id) => dispatch(addRegistration(registration, id)),
        resetErrors: () => dispatch(resetErrors()),
        getAge: (dob) => dispatch(getAge(dob)),
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EditRegistration);