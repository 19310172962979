import React from 'react'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import '../../css/footer.css'
import { compose } from 'redux'

const Footer = (props) => {
    const { auth, t } = props;

    return (
        <div class="bottom section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">

                        {auth.uid
                            ?
                            <div class="links">
                                <p>Quick Links:&nbsp;&nbsp;&nbsp;
                                    <Link class="qlink" to='/'>Home</Link>&nbsp;&nbsp;&nbsp;
                                    <Link class="qlink" to='/athletes'>Athletes</Link>&nbsp;&nbsp;&nbsp;
                                    <Link class="qlink" to='/registration'>Regsitrations</Link>&nbsp;&nbsp;&nbsp;
                                    <Link class="qlink" to='/report'>Report</Link>&nbsp;&nbsp;&nbsp;
                                    <Link class="qlink" to='/disclamer'>T&amp;C</Link>&nbsp;&nbsp;&nbsp;

                                </p>
                            </div>
                            :
                            <div class="links">
                                <p>Quick Links:&nbsp;&nbsp;&nbsp;
                                    <Link class="qlink" to='/signup'>Register</Link>&nbsp;&nbsp;&nbsp;
                                    <Link class="qlink" to='/signin'>Login</Link>&nbsp;&nbsp;&nbsp;
                                    <Link class="qlink" to='/resetpassword'>Forgot Password</Link>&nbsp;&nbsp;&nbsp;
                                    <Link class="qlink" to='/disclamer'>T&amp;C</Link>&nbsp;&nbsp;&nbsp;
                                </p>
                            </div>
                        }
                        <div class="problems">
                            <p><Trans i18nKey='footer_contact'><span></span></Trans><br /></p>
                        </div>
                        <div class="copyright">
                            <p>{t('footer_copyright')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    }
}

export default compose(withTranslation(), connect(mapStateToProps))
    (Footer)

