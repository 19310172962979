import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addAthlete, resetErrors } from '../../store/actions/athleteActions'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { validateVideo, validateForm, validateImage } from '../../store/actions/otherActions'
import { withTranslation } from 'react-i18next'

class AddAthlete extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    state = {
        errors: {
            name: ' ',
            surname: '',
            grade: '',
            sex: '',
            dob: '',
            image: '',
            video1: '',
            video2: '',
        },
    }
    file = null;
    fileDisplay = 'https://firebasestorage.googleapis.com/v0/b/maltaopenapplications.appspot.com/o/defaultProfile.png?alt=media&token=bbab7452-7462-4bb8-9cfc-bf57bf7d21e3';

    handleChange = (e) => {
        const { id, value } = e.target;
        this.props.resetErrors();

        let errors = this.state.errors;
        switch (id) {
            case 'name':
                errors.name =
                    value.length < 2 || value.length > 40
                        ? 'Name must be between 2 and 40 characters!'
                        : '';
                break;
            case 'surname':
                errors.surname =
                    value.length < 2 || value.length > 40
                        ? 'Surname must be between 2 and 40 characters!'
                        : '';
                break;
            case 'dob':
                errors.dob =
                    (new Date(value) > new Date('1/1/1930')) && (new Date(value) < new Date('1/1/2017'))
                        ? ''
                        : 'Date of Birth must be between 01/01/1930 and 01/01/2017!';
                break;
            case 'sex':
                errors.sex =
                    value.length === 0
                        ? 'Please select sex!'
                        : '';
                break;
            case 'para':
                errors.para =
                    value.length === 0
                        ? 'Please select an option!'
                        : '';
                break;
            case 'grade':
                errors.grade =
                    value.length === 0
                        ? 'Please select grade!'
                        : '';
                break;
            default:
                break;
        }

        this.setState({ errors, [id]: value })

    }
    handleImageChange = (e) => {
        const { id } = e.target;

        this.props.resetErrors();

        let errors = this.state.errors;
        let isImage = (e.target.files[0]) ? validateImage(e.target.files[0]) : false;

        switch (id) {
            case 'image':
                errors.image =
                    (isImage)
                        ? ''
                        : 'File must be an image!';
                break;
            default:
                break;
        }

        this.setState({ errors })

        if (errors.image === '') {
            this.file = e.target.files[0];
            URL.revokeObjectURL(this.fileDisplay);
            this.fileDisplay = URL.createObjectURL(this.file);
        } else {
            this.file = null;
            this.fileDisplay = 'https://firebasestorage.googleapis.com/v0/b/maltaopenapplications.appspot.com/o/defaultProfile.png?alt=media&token=bbab7452-7462-4bb8-9cfc-bf57bf7d21e3';
        }

    }
    handleEditPicture = (e) => {
        this.props.resetErrors();
        const fileInput = document.getElementById("image");
        fileInput.click();
    }
    handleVideoButton = (index, e) => {
        e.preventDefault();

        this.props.resetErrors();
        const fileInput = document.getElementById("video" + index);
        fileInput.click();

    }
    handleUploadVideo = (e) => {
        const { id } = e.target;

        let errors = this.state.errors;
        let video = this.state[id] ? this.state[id] : [];
        let isVideo = (e.target.files[0]) ? validateVideo(e.target.files[0]) : false;

        console.log(e.target.files[0]);
        if (isVideo && e.target.files[0]['size'] > 314572800) {
            errors[id] = 'Video must be less than 300MB';
        } else if (!isVideo) {
            errors[id] = 'File must be a video!';
        } else {
            errors[id] = '';
            video = e.target.files[0];
        }

        this.setState({ errors, [id]: video });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = this.state.errors;
        if (this.file === null) {
            errors.image = 'Photo required!';
        }
        if (document.getElementById("name").value.trim() === "") {
            errors.name = 'Name required!';
        }
        if (document.getElementById("surname").value.trim() === "") {
            errors.surname = 'Surname required!';
        }
        if (document.getElementById("dob").value.trim() === "") {
            errors.dob = 'Date of Birth required!';
        }
        if (document.getElementById("sex").value.trim() === "") {
            errors.sex = 'Please choose sex!';
        }
        if (document.getElementById("grade").value.trim() === "") {
            errors.grade = 'Please choose grade!';
        }
        if (!this.state.video1 || this.state.video1 === "") {
            errors.video1 = 'Upload a video!';
        }
        if (!this.state.video2 || this.state.video2 === "") {
            errors.video2 = 'Upload a video!';
        }

        this.setState({ errors })

        if (validateForm(this.state.errors)) {
            this.props.addAthlete(this.state, this.file, this.props.t('compID'));
            document.getElementById("form").reset();
            URL.revokeObjectURL(this.fileDisplay);
            this.file = null;
            this.fileDisplay = 'https://firebasestorage.googleapis.com/v0/b/maltaopenapplications.appspot.com/o/defaultProfile.png?alt=media&token=bbab7452-7462-4bb8-9cfc-bf57bf7d21e3';
        } else {
            console.error('Invalid Form')
        }
    }
    handleProofButton = (e) => {
        e.preventDefault();
        const fileInput = document.getElementById("proof");
        fileInput.click();
    }
    handleUploadProof = (e) => {
        const { id } = e.target;

        let errors = this.state.errors;

        switch (id) {
            case 'proof':
                errors.proof = (e.target.files[0]['size'] > 209715200) ? 'Proof must be less than 200MB' : '';
                break;
            default:
                break;
        }

        this.setState({ errors, [id]: e.target.files[0] })

    }
    componentWillUnmount() {
        this.props.resetErrors();

    }
    render() {
        const { addAthleteError, addAthleteSuccess } = this.props;
        return (
            <div class="col-12-md">

                <div class="card card-inverse card-info">

                    <form id="form" onSubmit={this.handleSubmit}>
                        <br /><h3 class="">Register Athlete</h3>

                        <div class="form-row">
                            <div class="form-group col-md-4 align-self-center">
                                <br />
                                <center>
                                    <img alt="" class="card-img-top" src={this.fileDisplay && this.fileDisplay} />
                                    {this.state.errors.image.length > 0 && <span className='error'>{this.state.errors.image}</span>}
                                    <button type="button" class="btn btn-light" onClick={this.handleEditPicture}>Upload Passport Photo</button>
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="image"
                                        hidden="hidden"
                                        onChange={this.handleImageChange}
                                    />
                                </center>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label htmlFor="name">Name*</label>
                                    <input type="text" class="form-control" id="name" onChange={this.handleChange} />
                                    {this.state.errors.name.length > 0 && <span className='error'>{this.state.errors.name}</span>}
                                </div>
                                <div class="form-group">
                                    <label htmlFor="surname">Surname*</label>
                                    <input type="text" class="form-control" id="surname" onChange={this.handleChange} />
                                    {this.state.errors.surname.length > 0 && <span className='error'>{this.state.errors.surname}</span>}
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label htmlFor="country">Sex*</label>
                                        <select class="form-control" id="sex" onChange={this.handleChange}>
                                            <option value=''>-Select Sex-</option>
                                            <option value="M">M</option>
                                            <option value="F">F</option>
                                        </select>
                                        {this.state.errors.sex.length > 0 && <span className='error'>{this.state.errors.sex}</span>}
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label htmlFor="dob">Date of Birth*</label>
                                        <input type="date" class="form-control" id="dob" onChange={this.handleChange} />
                                        {this.state.errors.dob.length > 0 && <span className='error'>{this.state.errors.dob}</span>}
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label htmlFor="grade">Grade*</label>
                                    <select class="form-control" id="grade" onChange={this.handleChange}>
                                        <option value=''>-Select Grade-</option>
                                        <option value="Open Grade">Open Grade</option>
                                        <option value="Novice (white belt)">Novice (White Bselt)</option>
                                        <option value="9th Kyu">9th Kyu</option>
                                        <option value="8th Kyu">8th Kyu</option>
                                        <option value="7th Kyu">7th Kyu</option>
                                        <option value="6th Kyu">6th Kyu</option>
                                        <option value="5th Kyu">5th Kyu</option>
                                        <option value="4th Kyu">4th Kyu</option>
                                        <option value="3rd Kyu">3rd Kyu</option>
                                        <option value="2nd Kyu">2nd Kyu</option>
                                        <option value="1st Kyu">1st Kyu</option>
                                        <option value="1st Dan">1st Dan</option>
                                        <option value="2nd Dan">2nd Dan</option>
                                        <option value="3rd Dan">3rd Dan</option>
                                        <option value="4th Dan">4th Dan</option>
                                        <option value="5th Dan">5th Dan</option>
                                        <option value="6th Dan">6th Dan</option>
                                        <option value="7th Dan">7th Dan</option>
                                        <option value="8th Dan">8th Dan</option>
                                    </select>
                                    {this.state.errors.grade.length > 0 && <span className='error'>{this.state.errors.grade}</span>}
                                </div>
                                <div class="form-group">
                                    <label htmlFor="grade">Video 1*: 30 seconds / 1minutes NORMAL LIFE (going to school / working / with family members / anything that is NOT KARATE / Martial Arts.</label>
                                    <button type="button" class="btn btn-primary" onClick={this.handleVideoButton.bind(this, 1)}>Upload Video 1</button>
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="video1"
                                        hidden="hidden"
                                        onChange={this.handleUploadVideo}
                                    />
                                    &nbsp;&nbsp;
                                    <span>{this.state.video1 ? this.state.video1.name : "Not Uploaded"}</span>
                                    <br />
                                    {this.state.errors.video1 && <span className='error'>{this.state.errors.video1}</span>}
                                </div>
                                <div class="form-group">
                                    <label htmlFor="grade">Video 2*: KATA / FORM of your choice  / or set of combinations, displaying Martial Arts.</label>
                                    <button type="button" class="btn btn-primary" onClick={this.handleVideoButton.bind(this, 2)}>Upload Video 2</button>
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="video2"
                                        hidden="hidden"
                                        onChange={this.handleUploadVideo}
                                    />
                                    &nbsp;&nbsp;
                                    <span>{this.state.video2 ? this.state.video2.name : "Not Uploaded"}</span>
                                    <br />
                                    {this.state.errors.video2 && <span className='error'>{this.state.errors.video2}</span>}
                                </div>
                                <div class="form-group">
                                    <label for="comments">Want to leave a comment? (Optional)</label>
                                    <textarea type="text" class="form-control" id="comments" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">Save</button>
                        {addAthleteError ? <div class="alert alert-danger" role="alert">{addAthleteError}</div> : null}
                        {addAthleteSuccess ? <div class="alert alert-success" role="alert">{addAthleteSuccess}</div> : null}
                    </form>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addAthleteError: state.athlete.addAthleteError,
        addAthleteSuccess: state.athlete.addAthleteSuccess,
        auth: state.firebase.auth,
        user: state.firestore.ordered.users && state.firestore.ordered.users.find(element => {
            return element.id === state.firebase.auth.uid
        })
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addAthlete: (athlete, image, compID) => dispatch(addAthlete(athlete, image, compID)),
        resetErrors: () => dispatch(resetErrors())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
    firestoreConnect(props => {
        return [
            {
                collection: "users"
            },
        ]
    })
)(AddAthlete)

/*
<option value="Novice (white belt)">Novice (white belt)</option>
                                            <option value="9th Kyu">9th Kyu</option>
                                            <option value="8th Kyu">8th Kyu</option>
                                            <option value="7th Kyu">7th Kyu</option>
                                            <option value="6th Kyu">6th Kyu</option>
                                            <option value="5th Kyu">5th Kyu</option>
                                            <option value="4th Kyu">4th Kyu</option>
                                             */