import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { firestoreConnect } from 'react-redux-firebase'
import CatSummary from './CatSummary'
import { getAge } from '../../store/actions/otherActions'
import { isAffiliated } from '../../store/actions/otherActions'
import { withTranslation } from 'react-i18next';

class IndividualSummary extends Component {

    handleRemoveRegister = (athlete, e) => {
        this.props.deleteRegistration(athlete.id)
        this.props.history.push('/redirect');
    }
    handleRegister = (athlete, e) => {
        const { individualCat } = this.props;

        this.props.history.push({ //browserHistory.push should also work here
            pathname: '/register/' + athlete.id,
            athlete: athlete,
            individualCat: individualCat
        });
    }
    handleEditRegister = (athlete, e) => {
        const { individualCat } = this.props;

        this.props.history.push({ //browserHistory.push should also work here
            pathname: '/register/' + athlete.id,
            athlete: athlete,
            individualCat: individualCat
        });
    }

    render() {
        const { athlete, individualCat, t } = this.props;

        let reg = (athlete.categories) ? ((Object.keys(athlete.categories).length > 0) ? true : false) : false;
        return (
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class={reg ? "card card-inverse card-info card-border-green" : "card card-inverse card-info card-border-red"}>
                    <div class="row">

                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <img class="card-img-top" alt="" src={athlete.image} />
                        </div>
                        <div class="col-sm-9 col-md-9 col-lg-9">
                            <div class="card-block" >
                                <div class="row">
                                    <div class="col-md-7 align-self-center">
                                        <h4 class="card-title">{athlete.name.toUpperCase()} {athlete.surname.toUpperCase()}</h4>
                                    </div>
                                    <div class="col-md-5 align-self-center d-flex flex-row-reverse">
                                        {(reg)
                                            ?
                                            <button type="button" class="btn btn-success" disabled>Registered</button>
                                            :
                                            <button type="button" class="btn btn-danger" disabled>Not Registered</button>
                                        }
                                        <h6 class="align-self-center">Status:&nbsp;</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="card-text">
                                            DOB: {athlete.dob.toUpperCase()} (Age: {getAge(athlete.dob)})
                                        </div>
                                        <div class="card-text">
                                            Grade: {athlete.grade.toUpperCase()}
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card-text">
                                            Sex: {athlete.sex}
                                        </div>
                                        <div class="card-text">
                                            {athlete.para === "true" ? <a href={athlete.proof}>View Proof</a> : ""}
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <center>
                                            <br />
                                            {(reg) ? <h3>€{Object.keys(athlete.categories).length * t('general_individualPrice') * (isAffiliated(this.props.auth) ? 8 / 10 : 1)}.00</h3> : <h3>€--</h3>}
                                        </center>
                                    </div>
                                </div>
                                {reg ?
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card-text">
                                                Categories:
                                            </div>
                                            {athlete.categories && Object.keys(athlete.categories).map(category => {
                                                return (
                                                    <div>
                                                        {individualCat && individualCat.map(cat => {
                                                            return (
                                                                // eslint-disable-next-line
                                                                (category == cat.num)
                                                                    ? <CatSummary athlete={athlete} cat={cat} category={athlete.categories[category]} />
                                                                    : ''
                                                            )
                                                        })}
                                                        <hr />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div> : ''}
                            </div>
                            <div class="card-footer">
                                <button type="button" class="btn btn-secondary" onClick={this.handleRegister.bind(this, athlete)}>Add Category</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        categories: state.firestore.ordered[`categories`] || []
    }

}

export default compose(
    withRouter,
    connect(mapStateToProps),
    firestoreConnect(props => {
        return [
            {
                collection: "users",
                doc: props.auth.uid,
                subcollections: [{
                    collection: "athletes",
                    doc: props.athlete.id,
                    subcollections: [{ collection: "categories", orderBy: ['category'] }]
                }]
                , storeAs: `categories`
            }
        ];
    }),
    withTranslation()
)(IndividualSummary);