import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withRouter } from 'react-router'
import { addRegistration, resetErrors } from '../../store/actions/registrationActions'
import { validateVideo, validateForm, getAge, capEachLetter, getKataList } from '../../store/actions/otherActions'
import '../../css/funkyradio.css'
import { withTranslation } from 'react-i18next'

class AddRegistration extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    componentWillMount() {
        this.props.resetErrors();
    }
    state = {
        errors: {
            category: '',
            style: '',
            video: [],
            kata: [],
        }
    }
    categoryErr = '';
    handleChange = (e) => {
        var { id, value } = e.target;
        const { athlete, individualCat } = this.props;

        let errors = this.state.errors;

        switch (id) {
            case 'style':
                errors.style =
                    value === ""
                        ? 'Select a style!'
                        : '';
                break;
            case 'category':
                if (value === "") {
                    errors.category = 'Select a category!';
                } else {
                    errors.category = '';
                    value = individualCat.find(cat => cat.num === value);
                    var array = [];
                    for (var i = 0; i < value.rounds; i++) {
                        array[i] = "";
                    }
                    errors.video = array.slice();
                    errors.kata = array.slice();
                    this.setState({ kata: array.slice(), video: array.slice() })
                    this.categoryErr = athlete.categories ?
                        Object.keys(athlete.categories).includes(value.num)
                            ? 'Warning: This category has already been added for this athlete. By uploading a new video you will be replcating the previous one.'
                            : ''
                        : '';
                }
                break;
            default:
                break;
        }

        this.setState({ errors, [id]: value })
    }
    handleKataChange = (key, e) => {
        var { id, value } = e.target;

        let errors = this.state.errors;
        let info = this.state[key];

        var index = id.includes(key) ? id.match(/\d+/)[0] : -1;
        errors[key][index] =
            value.length <= 0
                ? 'Field Required!'
                : '';

        info[index] = value;
        this.setState({ errors, [key]: info })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let errors = this.state.errors;

        if (!this.state.category || this.state.category === "") {
            errors.category = 'Select a category!';
        }
        if ((this.state.category.sport === "Karate" || this.state.category.sport === "Repner") && this.state.category.type === "Kata" && (!this.state.style || this.state.style === "")) {
            errors.style = 'Select a style!';
        }
        if (errors.category === "") {
            for (var i = 0; i < this.state.category.rounds; i++) {
                if (this.state.category.kataName == "TRUE" && (!this.state.kata || !this.state.kata[i] || this.state.kata[i] === "")) {
                    errors.kata[i] = 'Select a kata!';
                }
                if (!this.state.video || !this.state.video[i] || this.state.video[i] === "") {
                    errors.video[i] = 'Upload a video!';
                }
            }
        }

        this.setState({ errors })

        if (validateForm(this.state.errors)) {

            this.props.addRegistration(this.state, this.props.athlete.id, this.props.history, this.props.t('compID'));
            //document.getElementById("form").reset();

        } else {
            console.error('Invalid Form')
        }

    }
    handleCancel = (e) => {
        e.preventDefault();
        this.props.history.push('/registration');
        window.location.reload();
    }
    handleVideoButton = (index, e) => {
        e.preventDefault();

        this.props.resetErrors();
        const fileInput = document.getElementById("video" + index);
        fileInput.click();

    }
    handleUploadVideo = (e) => {
        const { id } = e.target;

        //this.props.resetErrors();

        let errors = this.state.errors;
        let video = this.state.video ? this.state.video : [];
        let isVideo = (e.target.files[0]) ? validateVideo(e.target.files[0]) : false;
        let index = id.match(/\d+/)[0];
        console.log(e.target.files[0]);
        if (isVideo && e.target.files[0]['size'] > 314572800) {
            errors.video[index] = 'Video must be less than 300MB';
        } else if (!isVideo) {
            errors.video[index] = 'File must be a video!';
        } else {
            errors.video[index] = '';
            video[index] = e.target.files[0];
        }

        this.setState({ errors, video: video });
    }
    componentWillUnmount() {
        this.props.resetErrors();
    }
    render() {
        const { athlete, individualCat, addRegistrationSuccess, styles } = this.props;

        var kumite = ["Red Belt Video", "White/Blue Belt Video"]
        var gradeMap = new Map();
        console.log(this.state.video && this.state.video[0] && this.state.video[0].name)
        // setting the values
        gradeMap.set('Open Grade', 10);
        gradeMap.set('Novice (white belt)', 10);
        gradeMap.set('9th Kyu', 9);
        gradeMap.set('8th Kyu', 8);
        gradeMap.set('7th Kyu', 7);
        gradeMap.set('6th Kyu', 6);
        gradeMap.set('5th Kyu', 5);
        gradeMap.set('4th Kyu', 4);
        gradeMap.set('3rd Kyu', 3);
        gradeMap.set('2nd Kyu', 2);
        gradeMap.set('1st Kyu', 1);
        gradeMap.set('1st Dan', -1);
        gradeMap.set('2nd Dan', -2);
        gradeMap.set('3rd Dan', -3);
        gradeMap.set('4th Dan', -4);
        gradeMap.set('5th Dan', -5);
        gradeMap.set('6th Dan', -6);
        gradeMap.set('7th Dan', -7);
        gradeMap.set('8th Dan', -8);
        gradeMap.set('1st Dan+', -1);

        return (
            <div class="col-12-md">
                <div class="card card-inverse card-info">
                    <div class="alert alert-info" role="alert">Note: Uploaded videos must not exceed 300MB.</div>
                    <form id="form" onSubmit={this.handleSubmit}>
                        <br /><h3 class="">Register Category for {athlete.name} {athlete.surname}</h3>

                        <div class="form-group">
                            <label htmlFor="category">Category*</label>
                            <select class="form-control" id="category" onChange={this.handleChange}>
                                <option value="">-- Select an Option --</option>
                                {individualCat && individualCat.map(cat => {
                                    return (
                                        (((cat.para === "TRUE") && (athlete.para === "true")) ||
                                            ((cat.para === "FALSE") &&
                                                (athlete.sex === cat.sex || cat.sex === 'B') &&
                                                (gradeMap.get(athlete.grade) >= cat.maxKyu && gradeMap.get(athlete.grade) <= cat.minKyu) &&
                                                (getAge(athlete.dob) >= cat.minAge && getAge(athlete.dob) <= cat.maxAge)))
                                            ? <option value={cat.num}>{cat.num} - {cat.sport} {cat.name} {cat.type}</option>
                                            : ''
                                    )
                                })}
                            </select>
                            {this.state.errors.category.length > 0 && <span className='error'>{this.state.errors.category}</span>}
                        </div>

                        {this.state.category && (this.state.category.sport === "Karate" || this.state.category.sport === "Repner") && this.state.category.type === "Kata" ?
                            <div class="form-group">
                                <label htmlFor="style">Style*</label>
                                <select class="form-control" id="style" onChange={this.handleChange}>
                                    <option value="">-- Select an Option --</option>
                                    {styles && styles.map(style => {
                                        console.log(style.id)
                                        if (this.state.category.sport === "Repner" && style.id.toLowerCase() === "repner") {
                                            return (
                                                <option value={style.id}>{capEachLetter(style.id)}</option>
                                            )
                                        } else if(this.state.category.sport === "Karate" && style.id.toLowerCase() !== "repner"){
                                            return (
                                                <option value={style.id}>{capEachLetter(style.id)}</option>
                                            )
                                        }
                                    })}
                                </select>
                                {this.state.errors.style.length > 0 && <span className='error'>{this.state.errors.style}</span>}
                            </div>
                            : ""}

                        {this.state.category
                            && ((this.state.style && this.state.category.sport === "Karate") || this.state.category.sport !== "Karate" || this.state.category.type == "Kumite")
                            && this.state.kata && this.state.kata.map((x, index) => {
                                return (
                                    <div class="form-row">
                                        <label>{this.state.category.type == "Kumite" ? kumite[index] : "Round " + (index + 1)}</label>
                                        {this.state.category.kataName == "FALSE" ? "" :
                                            <div class="form-group col-md-12">
                                                <label for={"kata" + index}>Name of Kata</label>
                                                {this.state.category.sport === "Karate" || this.state.category.sport === "Repner" ?
                                                    <select class="form-control" id={"kata" + index} onChange={this.handleKataChange.bind(this, "kata")}>
                                                        <option value="">-- Select an Option --</option>
                                                        {styles && this.state.style && getKataList(styles.find(s => s.id === this.state.style)).map(kata => {
                                                            return (
                                                                <option value={kata}>{capEachLetter(kata)}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    :
                                                    <input class="form-control" id={"kata" + index} onChange={this.handleKataChange.bind(this, "kata")} />}
                                                {this.state.errors.kata[index] && <span className='error'>{this.state.errors.kata[index]}</span>}
                                            </div>
                                        }
                                        {/*this.state.category.sport != "Kobudo" ? "" :
                                            <div class="form-group col-md-12">
                                                <label for={"weapon" + index}>Weapon</label>
                                                <input class="form-control" id={"weapon" + index} onChange={this.handleKataChange.bind(this, "weapon")} />
                                                {this.state.errors.weapon[index] && <span className='error'>{this.state.errors.weapon[index]}</span>}
                                            </div>
                                    */}
                                        <div class="form-group col-md-12">

                                            <button type="button" class="btn btn-primary" onClick={this.handleVideoButton.bind(this, index)}>Upload Video</button>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id={"video" + index}
                                                hidden="hidden"
                                                onChange={this.handleUploadVideo}
                                            />
                                        &nbsp;&nbsp;
                                        <span>{this.state.video[index] ? this.state.video[index].name : "Not Uploaded"}</span>
                                            <br />
                                            {this.state.errors.video[index] && <span className='error'>{this.state.errors.video[index]}</span>}
                                        </div>

                                    </div>

                                )
                            })}

                        <button type="submit" class="btn btn-primary">Save</button>
                        &nbsp;
                        <button type="button" class="btn btn-secondary" onClick={this.handleCancel}>Cancel</button>
                        {addRegistrationSuccess ? <div class="alert alert-success" role="alert">{addRegistrationSuccess}</div> : null}
                        {this.categoryErr !== '' ? <div class="alert alert-warning" role="alert">{this.categoryErr}</div> : null}
                    </form>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addRegistrationError: state.registration.addRegistrationError,
        addRegistrationSuccess: state.registration.addRegistrationSuccess,
        auth: state.firebase.auth,
        user: state.firestore.ordered.users && state.firestore.ordered.users.find(element => {
            return element.id === state.firebase.auth.uid
        }),
        styles: state.firestore.ordered[`styles`] || [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addRegistration: (registration, id, history, compID) => dispatch(addRegistration(registration, id, history, compID)),
        resetErrors: () => dispatch(resetErrors()),
        getAge: (dob) => dispatch(getAge(dob)),
    }
}

export default compose(
    withRouter,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => {
        return [
            {
                collection: "styles"
            }
        ];
    })
)(AddRegistration);