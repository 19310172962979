import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: "AIzaSyAgOKoWKC8s6kja3vPlX3QJcpNQUfrVsNY",
  authDomain: "otw2020-55c46.firebaseapp.com",
  databaseURL: "https://otw2020-55c46.firebaseio.com",
  projectId: "otw2020-55c46",
  storageBucket: "otw2020-55c46.appspot.com",
  messagingSenderId: "737210900440",
  appId: "1:737210900440:web:cbf5edb347b6b69ec51cad",
  measurementId: "G-R6X7SM1615"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase