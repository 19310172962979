const initState = {
  addTeamError: null,
  addTeamSuccess: null,
}

const teamReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ADD_TEAM_SUCCESS':
      return {
        ...state,
        addTeamError: null,
        addTeamSuccess: 'Team Added Successfully'
      };
    case 'ADD_TEAM_ERROR':
      return {
        ...state,
        addTeamError: action.err.message,
        addTeamSuccess: null
      };
    case 'EDIT_TEAM_SUCCESS':
      return {
        ...state,
        addTeamError: null,
        addTeamSuccess: 'Team Edited Successfuly'
      };
    case 'EDIT_TEAM_ERROR':
      return {
        ...state,
        addTeamError: action.err.message,
        addTeamSuccess: null
      };
    case 'RESET':
      return {
        ...state,
        addTeamError: null,
        addTeamSuccess: null
      };
    case 'UPLOAD':
      var per = parseFloat(action.progress).toFixed(2);
      return {
        ...state,
        addTeamError: null,
        addTeamSuccess: 'Uploading video/s: ' + per + '%'
      };
    default:
      return state;
  }
};

export default teamReducer; 