import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Sidebar from './Sidebar'
import Disclamer from './Disclamer'
import AppsClosed from './AppsClosed'

import VerifyEmail from '../auth/VerifyEmail'
import SignIn from '../auth/SignIn'
import SignUp from '../auth/SignUp'
import ResetPassword from '../auth/ResetPassword'
import { withRouter } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Athletes from '../athletes/Athletes'
import Individuals from '../individuals/Individuals'
import Pairs from '../pairs/Pairs'
import Teams from '../teams/Teams'
import Registrations from '../individuals/Registrations'
import DeleteRedirect from '../individuals/DeleteRedirect'
import Report from '../report/Report'
import '../../css/backround.css'
import Details from './Details'

class Dashboard extends Component {

  render() {
    const { auth, whitelist } = this.props;
    const status = whitelist[0]
      ? whitelist[0].users.includes(auth.email) ? false : whitelist[0].status
      : false;

    //(auth.emailVerified === false)
    return (
      <div>

        {auth.uid ?

          <div>
            {(true === false) ?
              <div>
                <div class="carousel slide carousel-fade" data-ride="carousel">

                  <div class="carousel-inner" role="listbox">
                    <div class="item active">
                    </div>
                    <div class="item">
                    </div>
                    <div class="item">
                    </div>
                  </div>
                </div>
                <div class="flex-wrapper-form">

                  <div class="container">
                    <div>
                      <br />
                      <Switch>
                        <Route component={VerifyEmail} />
                      </Switch>
                    </div>
                  </div>

                </div>
              </div>
              :
              <div class="container">
                <br />
                <div className="row">
                  <div className="col-sm-12 col-md-3 col-lg-3">
                    <Sidebar />
                  </div>
                  <div className="col-sm-12 col-md-9 col-lg-9">
                    <Switch>
                      <Route exact path='/' component={Home} />
                      <Route path='/details' component={status ? AppsClosed : Details} />
                      <Route path='/athletes' component={status ? AppsClosed : Athletes} />
                      <Route path='/editAthlete/:id' component={status ? AppsClosed : Athletes} />
                      <Route path='/teams' component={status ? AppsClosed : Teams} />
                      <Route path='/editTeam/:id' component={status ? AppsClosed : Teams} />
                      <Route path='/pairs' component={status ? AppsClosed : Pairs} />
                      <Route path='/editPair/:id' component={status ? AppsClosed : Pairs} />
                      <Route path='/registration' component={status ? AppsClosed : Individuals} />
                      <Route path='/register/:id' component={status ? AppsClosed : Registrations} />
                      <Route path='/redirect' component={status ? AppsClosed : DeleteRedirect} />
                      <Route path='/report' component={Report} />
                      <Route path='/disclamer' component={Disclamer} />
                      <Route component={Home} />
                    </Switch>
                  </div>
                </div>
              </div>

            }
          </div>

          :
          <div id="main">
            <div class="carousel slide carousel-fade" data-ride="carousel">

              <div class="carousel-inner" role="listbox">
                <div class="item active">
                </div>
                <div class="item">
                </div>
                <div class="item">
                </div>
              </div>
            </div>
            <div class="flex-wrapper-form">

              <div class="container">
                <br />
                <Switch>
                  <Route path='/signin' component={SignIn} />
                  <Route path='/signup' component={status ? AppsClosed : SignUp} />
                  <Route path='/resetPassword' component={ResetPassword} />
                  <Route path='/disclamer' component={Disclamer} />
                  <Route component={SignIn} />
                </Switch>
              </div>
            </div>
          </div>

        }

      </div>


    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    whitelist: state.firestore.ordered[`whitelist`] || [],
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  firestoreConnect(props => {
    return [
      {
        collection: "whitelist",
        doc: "whitelist",
        storeAs: `whitelist`
      }
    ];
  })
)(Dashboard);