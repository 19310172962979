import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Disclamer extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {

        return (
            <div class="card card-inverse card-info">
                <div class="card-block">
                    <br />
                    <div class="row">
                        <div class="col-md-12">
                            <h3><b>Terms &amp; Conditions</b></h3>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-text">
                                <p></p>
                                
                                <p>We are collecting, organizing and storing your personal data for the purposes of processing your application to the MKA ONLINE Chapionship. We will be using your personal data in compliance with the General Data Protection Regulation and shall not share this with any other third parties, while striving to ensure appropriate security of your personal data. Once the MKA ONLINE Chapionships are over and we have issued the relevant medal/trophy statistics and compiled the country honors list, we will be disposing of your personal data since this will no longer be required. This does not apply to the personal photo and the kata video that are uploaded for each of the persons forming part of a team, whereby we refer you to the consent request made available in the ‘register/create an account’ section of this website.</p>

                                <p>The event is open for participation to interested clubs and the Mediterranean Karate Alliance as the event's organizer, has a legitimate interest to ensure the success of the MKA ONLINE Chapionship. We will be using captures from the live video clips taken throughout the event for our marketing purposes.</p>

                                <p>You are hereby providing your consent to our promotional use of the personal photograph and the kata video uploaded. In case you are uploading the personal photo and kata video on behalf of another Coach, Official, or Competitor within your team, then you are accepting that you have requested this person's consent for the use of his personal photo and kata video as just described.</p>

                                <p>I confirm that all the Competitors, Coaches and Officials registered on this system have received my advice of and accept the following statement: </p>
                                <i>
                                    <p>“The Competitor, Coach, Official realizes and discharges the organizers and their representatives or agents and all other competitors from all claims that the competitor, coach, official or his/her personal representative may have for any injury (however caused) sustained by the competitor. </p>
                                    <p>The Competitor, Coach, Official agrees that live video captures of himself/herself will be taken during the event on all days, and that live video captures and any other material can be used for promotion for this or similar events in the future.”</p>
                                </i>
                                <p>Please enter all the details required accurately to avoid any disappointments on the day.</p>
                                <Link to='/signup'>Go Back</Link>
                            </div>
                        </div>

                    </div>
                    <br />

                </div>
            </div>

        )
    }
}

export default Disclamer;