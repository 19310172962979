import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { editAthlete } from '../../store/actions/athleteActions'
import { validateForm, validateImage } from '../../store/actions/otherActions'
import { Redirect } from 'react-router-dom'

class EditAthlete extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    state = {
        errors: {
            name: '',
            surname: '',
            grade: '',
            sex: '',
            dob: '',
            image: '',
        }
    }
    file = null;
    fileDisplay = null;
    handleChange = (e) => {
        const { id, value } = e.target;

        let errors = this.state.errors;

        switch (id) {
            case 'name':
                errors.name =
                    value.length < 2 || value.length > 40
                        ? 'Name must be between 2 and 40 characters!'
                        : '';
                break;
            case 'surname':
                errors.surname =
                    value.length < 2 || value.length > 40
                        ? 'Surname must be between 2 and 40 characters!'
                        : '';
                break;
            case 'dob':
                errors.dob =
                    (new Date(value) > new Date('1/1/1930')) && (new Date(value) < new Date('1/1/2017'))
                        ? ''
                        : 'Date of Birth must be between 01/01/1930 and 01/01/2017!';
                break;
            case 'sex':
                errors.sex =
                    value.length === 0
                        ? 'Please select sex!'
                        : '';
                break;
            case 'grade':
                errors.grade =
                    value.length === 0
                        ? 'Please select grade!'
                        : '';
                break;
            default:
                break;
        }

        this.setState({ errors, [id]: value })

    }
    handleImageChange = (e) => {
        const { id } = e.target;

        let errors = this.state.errors;
        let isImage = (e.target.files[0]) ? validateImage(e.target.files[0]) : false;

        switch (id) {
            case 'image':
                errors.image =
                    (isImage)
                        ? ''
                        : 'File must be an image!';
                break;
            default:
                break;
        }

        this.setState({ errors })

        if (errors.image === '') {
            this.file = e.target.files[0];
            this.fileDisplay = URL.createObjectURL(this.file);
        } else {
            this.file = null;
            this.fileDisplay = 'https://firebasestorage.googleapis.com/v0/b/maltaopenapplications.appspot.com/o/defaultProfile.png?alt=media&token=bbab7452-7462-4bb8-9cfc-bf57bf7d21e3';
        }

    }
    handleClose = (e) => {
        this.props.history.push('/athletes')
    }
    handleEditPicture = (e) => {
        const fileInput = document.getElementById("image");
        fileInput.click();
    }
    handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm(this.state.errors)) {
            this.props.editAthlete(this.state, this.file, this.props.location.athlete.id);
            this.props.history.push('/athletes');
        } else {
            console.error('Invalid Form')
        }
    }

    componentWillReceiveProps() {
        document.getElementById("form").reset();
        this.file = null;
        this.fileDisplay = null;
        window.scrollTo(0, 0);
    }

    render() {

        const { editAthleteError, editAthleteSuccess, athlete } = this.props;
        if (!athlete) return <Redirect to='/athletes' />
        if (this.fileDisplay == null) this.fileDisplay = athlete && athlete.image;

        return (
            <div class="col-12-md">

                <div class="card card-inverse card-info">

                    <form id="form" onSubmit={this.handleSubmit}>
                        <br /><h3 class="">Edit Athlete</h3>


                        <div class="form-row">
                            <div class="form-group col-md-4 align-self-center">
                                <br />
                                <center><img class="card-img-top" alt="" src={this.fileDisplay && this.fileDisplay} />
                                    {this.state.errors.image.length > 0 && <span className='error'>{this.state.errors.image}</span>}
                                    <button type="button" class="btn btn-light" onClick={this.handleEditPicture}>Upload Passport Photo</button>
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="image"
                                        hidden="hidden"
                                        onChange={this.handleImageChange}
                                    /></center>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label htmlFor="name">Name*</label>
                                    <input type="text" class="form-control" id="name" onChange={this.handleChange} defaultValue={athlete && athlete.name} />
                                    {this.state.errors.name.length > 0 && <span className='error'>{this.state.errors.name}</span>}
                                </div>
                                <div class="form-group">
                                    <label htmlFor="surname">Surname*</label>
                                    <input type="text" class="form-control" id="surname" onChange={this.handleChange} defaultValue={athlete && athlete.surname} />
                                    {this.state.errors.surname.length > 0 && <span className='error'>{this.state.errors.surname}</span>}
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label htmlFor="country">Sex*</label>
                                        <select class="form-control" id="sex" onChange={this.handleChange}>
                                            <option value=''>-Select Sex-</option>
                                            <option selected="selected" value={athlete && athlete.sex}>{athlete && athlete.sex}</option>
                                            {(athlete && athlete.sex === 'M')
                                                ? <option value="F">F</option>
                                                : <option value="M">M</option>}
                                        </select>
                                        {this.state.errors.sex.length > 0 && <span className='error'>{this.state.errors.sex}</span>}
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label htmlFor="dob">Date of Birth*</label>
                                        <input type="date" class="form-control" id="dob" onChange={this.handleChange} defaultValue={athlete && athlete.dob} />
                                        {this.state.errors.dob.length > 0 && <span className='error'>{this.state.errors.dob}</span>}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label htmlFor="grade">Grade*</label>
                                    <select class="form-control" id="grade" onChange={this.handleChange}>
                                        <option value=''>-Select Grade-</option>
                                        <option selected="selected">{athlete && athlete.grade}</option>
                                        <option value="Novice (white belt)">Novice (white belt)</option>
                                        <option value="9th Kyu">9th Kyu</option>
                                        <option value="8th Kyu">8th Kyu</option>
                                        <option value="7th Kyu">7th Kyu</option>
                                        <option value="6th Kyu">6th Kyu</option>
                                        <option value="5th Kyu">5th Kyu</option>
                                        <option value="4th Kyu">4th Kyu</option>
                                        <option value="3rd Kyu">3rd Kyu</option>
                                        <option value="2nd Kyu">2nd Kyu</option>
                                        <option value="1st Kyu">1st Kyu</option>
                                        <option value="1st Dan">1st Dan</option>
                                        <option value="2nd Dan">2nd Dan</option>
                                        <option value="3rd Dan">3rd Dan</option>
                                        <option value="4th Dan">4th Dan</option>
                                        <option value="5th Dan">5th Dan</option>
                                        <option value="6th Dan">6th Dan</option>
                                        <option value="7th Dan">7th Dan</option>
                                        <option value="8th Dan">8th Dan</option>
                                    </select>
                                    {this.state.errors.grade.length > 0 && <span className='error'>{this.state.errors.grade}</span>}
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">Save Changes</button>&nbsp;&nbsp;
                        <button type="button" class="btn btn-secondary" onClick={this.handleClose}>Close</button>
                        {editAthleteError ? <div class="alert alert-danger" role="alert">{editAthleteError}</div> : null}
                        {editAthleteSuccess ? <div class="alert alert-success" role="alert">{editAthleteSuccess}</div> : null}
                    </form>
                </div>
                <br />
                <div class="alert alert-warning" role="alert">Editing athlete will remove all their individual registrations. Head over to Individual Categories Registrations after editing to reregister the athlete.</div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        athleteError: state.athlete.athleteError,
        athleteSuccess: state.athlete.athleteSuccess,
        auth: state.firebase.auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editAthlete: (club, image, id) => dispatch(editAthlete(club, image, id))
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EditAthlete);