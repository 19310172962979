
export const editClub = (user, compID) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore();
    const authorId = getState().firebase.auth.uid;

    const doc = firestore.collection('users').doc(authorId);

    doc.set({
      ...user,
      errors: getFirebase().firestore.FieldValue.delete()
    }, {merge: true}).then(() => {
      dispatch({ type: 'EDIT_CLUB_SUCCESS', user });
    }).catch(err => {
      dispatch({ type: 'EDIT_CLUB_ERROR', err });
    });
  }
}; 

export const editName = (name) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    var user = getFirebase().auth().currentUser;

    user.updateProfile({
      displayName: name,
    })
  }
}; 

export const resetErrors = () => {
  return (dispatch) => {
      dispatch({ type: 'RESET' });
  }
};