export const addRegistration = (registration, id, history, compID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid;

        const doc = firestore.collection('users').doc(authorId).collection('athletes').doc(id);
        const firebase = getFirebase();
        const storageRef = firebase.storage().ref();

        var metadata = {
            contentType: 'video/mp4'
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        addVideo(dispatch, firebase, storageRef, authorId, doc, metadata, registration, id, history, 1);
    }
};

export const addVideo = (dispatch, firebase, storageRef, authorId, doc, metadata, registration, id, history, i) => {
        // make async call to database

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.child(authorId + "/" + id + '/' + registration.category.num + '/' + i).put(registration.video[i-1], metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / registration.category.rounds) + ((i - 1) * (100/ registration.category.rounds));
                dispatch({ type: 'UPLOAD', progress });
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            }, function (error) {
                console.log("error");
            }, function () {
                // Upload completed successfully, now we can get the download URL
                //registration.proof && uploadProof(authorId + "/" + id + '/' + registration.category + "Proof", registration, getFirebase(), doc);
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    var round = {
                        categories: {
                            [registration.category.num]: {
                                [i]: {
                                    video: downloadURL,
                                    style: registration.style ? registration.style : "",
                                    kata: registration.category.kataName == "TRUE" ? registration.kata[i - 1] : ""
                                }
                            }
                        }
                    };
                    doc.set(round, { merge: true }).then(() => {
                        if (i != registration.category.rounds) {
                            addVideo(dispatch, firebase, storageRef, authorId, doc, metadata, registration, id, history, i + 1);
                        }
                        else {
                            dispatch({ type: 'RESET_REG' });
                            history.push('/registration');
                        }
                    });

                });

            });

};

export const deleteRegistration = (id, category) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid;

        const doc = firestore.collection('users').doc(authorId).collection('athletes').doc(id);

        doc.set({
            categories: { [category]: getFirebase().firestore.FieldValue.delete() },
        }, { merge: true }).then(function () {
            getFirebase().storage().ref().child(authorId + "/" + id + "/" + category).delete();
            if (category.includes("41") || category.includes("42") || category.includes("43") || category.includes("44")) {
                getFirebase().storage().ref().child(`${authorId}/${id}/${category}Proof`).delete();
            }
            console.log("Document successfully deleted!");
        }).catch(function (error) {
            console.error("Error removing document: ", error);
        });
    }
};

export const resetErrors = () => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'RESET' });

    }
};

export const uploadProof = (location, registration, firebase, doc) => {
    // make async call to database

    const storageRef = firebase.storage().ref();

    var metadata = {
        contentType: registration.proof.type
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child(location).put(registration.proof, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    break;
            }
        }, function (error) {

            console.log("error");
        }, function () {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                doc.set({
                    categories: {
                        [registration.category]: {
                            proof: downloadURL,
                        }
                    }
                }, { merge: true });
            });
        });

};